<template>
  <div>
    <v-app>
      <AppBar
        @evExecCode="evExecCodeProc"
        @evNewCode="evNewCodeProc"
        @evStepback="evStepBackProc"
        @evStepForward="evStepForwardProc"
        @evCopyBlocks="evCopyBlocksProc"
        @evCopyCode="evCopyCodeProc"
      />
      <Blocks ref="blocksView" />
      <!-- <v-footer color="primary" app> footer </v-footer> -->
    </v-app>

    <ExecutionResult />
  </div>
</template>

<script setup>
import AppBar from "./../components/AppBar.vue";
import Blocks from "./../components/BlockView.vue";
import ExecutionResult from "./../components/ExecutionResult.vue";
</script>

<script>
export default {
  name: "MainItem",
  methods: {
    evNewCodeProc() {
      // console.log("parent event");
      this.$refs.blocksView.newCode();
    },
    evStepBackProc() {
      // console.log("parent event");
      this.$refs.blocksView.stepBack();
    },
    evStepForwardProc() {
      // console.log("parent event");
      this.$refs.blocksView.stepForward();
    },
    evCopyBlocksProc() {
      // console.log("parent event");
      this.$refs.blocksView.copyBlocks();
    },
    evExecCodeProc() {
      // this.$emit("parent-event");
      // console.log("parent event");
      this.$refs.blocksView.execCode();
    },
    evCopyCodeProc() {
      // this.$emit("parent-event");
      // console.log("parent event");
      this.$refs.blocksView.copyCode();
    },
  },
};
</script>

<style scoped></style>
