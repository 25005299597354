/**
 * @license
 * Copyright 2021 Google LLC
 * SPDX-License-Identifier: Apache-2.0
 */

/**
 * @fileoverview Complete helper functions for generating C# for
 *     blocks.  This is the entrypoint for csharp_compressed.js.
 * @suppress {extraRequire}
 */

// Former goog.module ID: Blockly.csharp.all

import {CSharpGenerator} from './csharp/csharp_generator.js';
// import * as colour from './csharp/colour.js';
import * as lists from './csharp/lists.js';
import * as logic from './csharp/logic.js';
import * as loops from './csharp/loops.js';
import * as math from './csharp/math.js';
import * as procedures from './csharp/procedures.js';
import * as text from './csharp/text.js';
import * as variables from './csharp/variables.js';
import * as variablesDynamic from './csharp/variables_dynamic.js';

export * from './csharp/csharp_generator.js';

/**
 * C# code generator instance.
 * @type {!CSharpGenerator}
 */
export const csharpGenerator = new CSharpGenerator();

// Install per-block-type generator functions:
Object.assign(
  csharpGenerator.forBlock,
  /*colour,*/lists, logic, loops, math, procedures,
  text, variables, variablesDynamic
);
