<template>
  <v-app-bar
    elevation="4"
    color="orange lighten-1"
    rounded
    style="margin: 0px 6px 2px 0px"
  >
    <v-app-bar-title>
      <v-row class="align-center justify-left">
        <v-col cols="auto">
          <v-icon icon="mdi-circle-slice-4" />
        </v-col>
        <v-col cols="auto">
          <v-row class="align-center justify-left">
            <div class="SystemName">Broccoly Coder</div>
          </v-row>
          <v-row class="align-center justify-left">
            <div class="AppName">C# Blocks</div>
          </v-row>
        </v-col>
      </v-row>
    </v-app-bar-title>
    <v-spacer />

    <v-btn
      stacked
      text
      Elevation="0"
      @click="menuNew"
      style="height: 60px; margin-top: 10px"
    >
      <div class="d-flex flex-column">
        <div>新規</div>
        <v-icon color="black">mdi-new-box</v-icon>
      </div>
    </v-btn>
    <v-btn
      stacked
      text
      Elevation="0"
      @click="menuStepback"
      style="height: 60px; margin-top: 10px"
    >
      <div class="d-flex flex-column">
        <div>戻す</div>
        <v-icon>mdi-arrow-left-bold</v-icon>
      </div>
    </v-btn>
    <v-btn
      stacked
      text
      Elevation="0"
      @click="menuStepForward"
      style="height: 60px; margin-top: 10px"
    >
      <div class="d-flex flex-column">
        <div>進む</div>
        <v-icon>mdi-arrow-right-bold</v-icon>
      </div>
    </v-btn>
    <!-- <v-btn
      stacked
      text
      Elevation="0"
      @click="menuCopyBlocks"
      style="height: 60px; margin-top: 10px"
    >
      <div class="d-flex flex-column">
        <div>コピー</div>
        <v-icon>mdi-content-copy</v-icon>
      </div>
    </v-btn> -->
    <!-- <v-btn
      stacked
      text
      Elevation="0"
      @click="menuPasteBlocks"
      style="height: 60px; margin-top: 10px"
    >
      <div class="d-flex flex-column">
        <div>貼付け</div>
        <v-icon>mdi-content-paste</v-icon>
      </div>
    </v-btn> -->
    <v-btn
      stacked
      text
      Elevation="0"
      @click="menuCopyCode"
      style="height: 60px; margin-top: 10px"
    >
      <div class="d-flex flex-column">
        <div>コードコピー</div>
        <v-icon>mdi-sticker-text</v-icon>
      </div>
    </v-btn>
    <v-btn
      stacked
      text
      Elevation="0"
      @click="menuExecCode"
      style="height: 60px; margin-top: 10px"
    >
      <div class="d-flex flex-column">
        <div>実行</div>
        <v-icon>mdi-play-circle</v-icon>
      </div>
    </v-btn>
    <!-- <v-btn
      stacked
      text
      Elevation="0"
      @click="menuBreakCode"
      style="height: 60px; margin-top: 10px"
    >
      <div class="d-flex flex-column">
        <div>停止</div>
        <v-icon>mdi-stop-circle-outline</v-icon>
      </div>
    </v-btn> -->
    <!-- </v-row> -->
  </v-app-bar>
</template>

<script>
export default {
  methods: {
    menuNew() {
      console.log("menuNew");
      this.$emit("evNewCode");
    },
    menuStepback() {
      console.log("menuStepback");
      this.$emit("evStepback");
    },
    menuStepForward() {
      console.log("menuStepForward");
      this.$emit("evStepForward");
    },
    menuCopyBlocks() {
      console.log("menuCopyBlocks");
      this.$emit("evCopyBlocks");
    },
    menuExecCode() {
      console.log("menuExecCode");
      this.$emit("evExecCode");
    },
    menuCopyCode() {
      console.log("menuCopyCode");
      this.$emit("evCopyCode");
    },

    menuPasteBlocks() {},

    menuCutBlocks() {},

    menuBreakCode() {},
  },
};
</script>

<script setup>
// var overlay = false;
</script>

<style scoped>
@import url("https://fonts.googleapis.com/css2?family=Handlee&display=swap");

div.SystemName {
  margin: 0.6rem 0 0 0;
  line-height: 1.5;
  font-size: 8px;
  width: 150px;
}

div.AppName {
  margin: -0.4rem -0.4rem 0 -0.8rem;
  display: block;
  width: 150px;
  font-size: 32px;
  text-align: center;
  font-family: "Handlee", cursive;
}

/* @media (min-width: 1024px) {
  div.SystemName {
    margin: 0.6rem 0 0 0;
    display: block;
    text-align: left;
    line-height: 1.5;
    font-size: 8px;
    width: 100px;
  }

  div.AppName {
    margin: -0.4rem 0 0 0;
    display: block;
    width: 100px;
    font-size: 24px;
    text-align: left;
    font-family: "Handlee", cursive;
  }
} */
</style>
