/**
 * @license
 * Copyright 2012 Google LLC
 * SPDX-License-Identifier: Apache-2.0
 */

/**
 * @fileoverview Generating C# for variable blocks.
 */

// Former goog.module ID: Blockly.csharp.variables

import {Order} from './csharp_generator.js';


export function variables_get(block, generator) {
  // Variable getter.
  const code = generator.getVariableName(block.getFieldValue('VAR'));
  return [code, Order.ATOMIC];
};

export function variables_set(block, generator) {
  // Variable setter.
  const argument0 = generator.valueToCode(
                        block, 'VALUE', Order.ASSIGNMENT) || '0';
  const varName = generator.getVariableName(block.getFieldValue('VAR'));
  return varName + ' = ' + argument0 + ';\n';
};

// cs
export function variables_define(block, generator) {
  // Variable define.
  // Variable setter.
  // const argument0 = generator.valueToCode(
  //   block, 'VALUE', Order.ASSIGNMENT) || '0';
  // const varName = generator.getVariableName(block.getFieldValue('VAR'));
  // return varName + ' = ' + argument0 + ';\n';

  var varAccess = block.getFieldValue("varAccess");
  var varType = block.getFieldValue("varType");
  const varName = generator.getVariableName(block.getFieldValue('VAR'));

  return `${varAccess} ${varType} ${varName};\n`;
};

export function variables_define_set(block, generator) {
  var varAccess = block.getFieldValue("varAccess");
  var varType = block.getFieldValue("varType");
  const varName = generator.getVariableName(block.getFieldValue('VAR'));
  const argument0 = generator.valueToCode(
    block, 'VALUE', Order.ASSIGNMENT) || '0';

  return `${varAccess} ${varType} ${varName} = ${argument0};\n`;
};

export function variables_define_array(block, generator) {
  var varAccess = block.getFieldValue("varAccess");
  var varType = block.getFieldValue("varType");
  const varName = generator.getVariableName(block.getFieldValue('VAR'));

  return `${varAccess} ${varType}[] ${varName};\n`;
};

export function variables_define_array_set(block, generator) {
  var varAccess = block.getFieldValue("varAccess");
  var varType = block.getFieldValue("varType");
  const varName = generator.getVariableName(block.getFieldValue('VAR'));
  const argument0 = generator.valueToCode(
    block, 'VALUE', Order.ASSIGNMENT) || '0';

  return `${varAccess} ${varType}[] ${varName} = ${argument0};\n`;
};

export function variables_array_get(block, generator) {
  var varAccess = block.getFieldValue("varAccess");
  var varType = block.getFieldValue("varType");
  const varName = generator.getVariableName(block.getFieldValue('VAR'));
  const argument0 = generator.valueToCode(
    block, 'VALUE', Order.ASSIGNMENT) || '0';

    return [`${varName}[${argument0}]`, Order.ATOMIC];
};
