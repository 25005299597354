import * as Blockly from "blockly/core";
import { csharpGenerator, Order } from "blockly/csharp";
import * as BlockColor from "./const.js";

// ブロックの配色
var cs_logic_block_color = BlockColor.cs_block_color;

// Timerブロック定義
const category_logicDef = `
    <category name="Logic" colour="${cs_logic_block_color}">
    <block type="csharp_logic_null_value"></block>
    <block type="csharp_logic_boolean_value"></block>
    <block type="csharp_logic_boolean_comparisonExpression"></block>
    <block type="csharp_logic_comparisonExpression"></block>
    <block type="csharp_logic_and_or"></block>
    <block type="csharp_logic_not"></block>
    <block type="csharp_logic_if"></block>
    <block type="csharp_logic_elseif"></block>
    <block type="csharp_logic_else"></block>
    <block type="csharp_logic_if_question"></block>
    <block type="csharp_logic_switch"></block>
    <block type="csharp_logic_case"></block>
    <block type="csharp_logic_case_default"></block>
    <block type="csharp_logic_break"></block>
    </category>`;

export { category_logicDef };

///////////////////////////////////////////////////////////////////
// null
Blockly.Blocks["csharp_logic_null_value"] = {
  init: function () {
    this.appendDummyInput().appendField("null");
    this.setInputsInline(true);
    this.setPreviousStatement(false, null);
    this.setNextStatement(false, null);
    this.setOutput(true, null);
    this.setColour(cs_logic_block_color);
    this.setTooltip("boolean");
    this.setHelpUrl("");
  },
};

csharpGenerator.forBlock["csharp_logic_null_value"] = function (
  block,
  generator
) {
  // using
  generator.definitions_["import_system"] = "using System;";
  // Parameter

  // Code
  var codeText = `null`;

  return [codeText, Order.NONE];
};

///////////////////////////////////////////////////////////////////
// true or false
Blockly.Blocks["csharp_logic_boolean_value"] = {
  init: function () {
    this.appendDummyInput().appendField(
      new Blockly.FieldDropdown([
        ["true", "true"],
        ["false", "false"],
      ]),
      "boolValue"
    );

    this.setInputsInline(true);
    this.setPreviousStatement(false, null);
    this.setNextStatement(false, null);
    this.setOutput(true, null);
    this.setColour(cs_logic_block_color);
    this.setTooltip("boolean");
    this.setHelpUrl("");
  },
};

csharpGenerator.forBlock["csharp_logic_boolean_value"] = function (
  block,
  generator
) {
  // using
  generator.definitions_["import_system"] = "using System;";
  // Parameter
  var boolValue = block.getFieldValue("boolValue");

  // Code
  var codeText = `${boolValue}`;

  return [codeText, Order.NONE];
};

///////////////////////////////////////////////////////////////////
// true or false
Blockly.Blocks["csharp_logic_boolean_comparisonExpression"] = {
  init: function () {
    this.appendDummyInput().appendField(
      new Blockly.FieldDropdown([
        ["true", "true"],
        ["false", "false"],
      ]),
      "boolValue"
    );

    this.setInputsInline(true);
    this.setPreviousStatement(false, null);
    this.setNextStatement(false, null);
    this.setOutput(true, "Boolean");
    this.setColour(cs_logic_block_color);
    this.setTooltip("boolean");
    this.setHelpUrl("");
  },
};

csharpGenerator.forBlock["csharp_logic_boolean_comparisonExpression"] =
  function (block, generator) {
    // using
    generator.definitions_["import_system"] = "using System;";
    // Parameter
    var boolValue = block.getFieldValue("boolValue");

    // Code
    var codeText = `${boolValue}`;

    return [codeText, Order.NONE];
  };

///////////////////////////////////////////////////////////////////
// 比較式
Blockly.Blocks["csharp_logic_comparisonExpression"] = {
  init: function () {
    this.appendDummyInput();
    this.appendValueInput("compA").setCheck(null);
    this.appendDummyInput().appendField(
      new Blockly.FieldDropdown([
        ["==", "=="],
        ["!=", "!="],
        [">", ">"],
        [">=", ">="],
        ["<", "<"],
        ["<=", "<="],
      ]),
      "comparisonExpression"
    );
    this.appendValueInput("compB").setCheck(null);

    this.setInputsInline(true);
    this.setPreviousStatement(false, null);
    this.setNextStatement(false, null);
    this.setOutput(true, "Boolean");
    this.setColour(cs_logic_block_color);
    this.setTooltip("class");
    this.setHelpUrl("");
  },
};

csharpGenerator.forBlock["csharp_logic_comparisonExpression"] = function (
  block,
  generator
) {
  // using
  generator.definitions_["import_system"] = "using System;";
  // Parameter
  var compA = generator.valueToCode(block, "compA", Order.NONE);
  var compB = generator.valueToCode(block, "compB", Order.NONE);
  var comparisonExpression = block.getFieldValue("comparisonExpression");

  // Code
  var codeText = `(${compA}) ${comparisonExpression} (${compB})`;

  return [codeText, Order.NONE];
};

///////////////////////////////////////////////////////////////////
// AND OR
Blockly.Blocks["csharp_logic_and_or"] = {
  init: function () {
    this.appendDummyInput();
    this.appendValueInput("compA").setCheck(null);
    this.appendDummyInput().appendField(
      new Blockly.FieldDropdown([
        ["&&", "&&"],
        ["||", "||"],
      ]),
      "comparisonExpression"
    );
    this.appendValueInput("compB").setCheck(null);

    this.setInputsInline(true);
    this.setPreviousStatement(false, null);
    this.setNextStatement(false, null);
    this.setOutput(true, "Boolean");
    this.setColour(cs_logic_block_color);
    this.setTooltip("class");
    this.setHelpUrl("");
  },
};

csharpGenerator.forBlock["csharp_logic_and_or"] = function (block, generator) {
  // using
  generator.definitions_["import_system"] = "using System;";
  // Parameter
  var compA = generator.valueToCode(block, "compA", Order.NONE);
  var compB = generator.valueToCode(block, "compB", Order.NONE);
  var comparisonExpression = block.getFieldValue("comparisonExpression");

  // Code
  var codeText = `(${compA}) ${comparisonExpression} (${compB})`;

  return [codeText, Order.NONE];
};

///////////////////////////////////////////////////////////////////
// AND OR
Blockly.Blocks["csharp_logic_not"] = {
  init: function () {
    this.appendDummyInput().appendField("!");
    this.appendDummyInput();
    this.appendValueInput("compA").setCheck(null);

    this.setInputsInline(true);
    this.setPreviousStatement(false, null);
    this.setNextStatement(false, null);
    this.setOutput(true, "Boolean");
    this.setColour(cs_logic_block_color);
    this.setTooltip("not");
    this.setHelpUrl("");
  },
};

csharpGenerator.forBlock["csharp_logic_not"] = function (block, generator) {
  // using
  generator.definitions_["import_system"] = "using System;";
  // Parameter
  var compA = generator.valueToCode(block, "compA", Order.NONE);
  // Code
  var codeText = `!(${compA})`;

  return [codeText, Order.NONE];
};

///////////////////////////////////////////////////////////////////
// if
Blockly.Blocks["csharp_logic_if"] = {
  init: function () {
    this.appendDummyInput().appendField("if (");
    this.appendDummyInput();
    this.appendValueInput("compA").setCheck("Boolean");
    this.appendDummyInput().appendField(")");

    this.appendStatementInput("Codes").setCheck(null);
    this.setInputsInline(true);
    this.setPreviousStatement(true, null);
    this.setNextStatement(true, null);
    this.setColour(cs_logic_block_color);
    this.setTooltip("if");
    this.setHelpUrl("");
  },
};

csharpGenerator.forBlock["csharp_logic_if"] = function (block, generator) {
  // using
  generator.definitions_["import_system"] = "using System;";
  // Parameter
  var compA = generator.valueToCode(block, "compA", Order.NONE);
  var Codes = generator.statementToCode(block, "Codes");

  // Code
  var codeText = `if (${compA}){
    ${Codes}
}
`;

  return codeText;
};

///////////////////////////////////////////////////////////////////
// else if
Blockly.Blocks["csharp_logic_elseif"] = {
  init: function () {
    this.appendDummyInput().appendField("else if (");
    this.appendDummyInput();
    this.appendValueInput("compA").setCheck("Boolean");
    this.appendDummyInput().appendField(")");

    this.appendStatementInput("Codes").setCheck(null);
    this.setInputsInline(true);
    this.setPreviousStatement(true, null);
    this.setNextStatement(true, null);
    this.setColour(cs_logic_block_color);
    this.setTooltip("if");
    this.setHelpUrl("");
  },
};

csharpGenerator.forBlock["csharp_logic_elseif"] = function (block, generator) {
  // using
  generator.definitions_["import_system"] = "using System;";
  // Parameter
  var compA = generator.valueToCode(block, "compA", Order.NONE);
  var Codes = generator.statementToCode(block, "Codes");

  // Code
  var codeText = `else if (${compA}){
    ${Codes}
}
`;

  return codeText;
};

///////////////////////////////////////////////////////////////////
// else
Blockly.Blocks["csharp_logic_else"] = {
  init: function () {
    this.appendDummyInput().appendField("else");

    this.appendStatementInput("Codes").setCheck(null);
    this.setInputsInline(true);
    this.setPreviousStatement(true, null);
    this.setNextStatement(true, null);
    this.setColour(cs_logic_block_color);
    this.setTooltip("else");
    this.setHelpUrl("");
  },
};

csharpGenerator.forBlock["csharp_logic_else"] = function (block, generator) {
  // using
  generator.definitions_["import_system"] = "using System;";
  // Parameter
  var Codes = generator.statementToCode(block, "Codes");

  // Code
  var codeText = `else{
    ${Codes}
}
`;

  return codeText;
};

///////////////////////////////////////////////////////////////////
// if
Blockly.Blocks["csharp_logic_if_question"] = {
  init: function () {
    this.appendValueInput("comp").setCheck(null);
    this.appendDummyInput().appendField("?");
    this.appendValueInput("resultA").setCheck(null);
    this.appendDummyInput().appendField(":");
    this.appendValueInput("resultB").setCheck(null);

    this.setInputsInline(true);
    this.setPreviousStatement(false, null);
    this.setNextStatement(false, null);
    this.setOutput(true, null);
    this.setColour(cs_logic_block_color);
    this.setTooltip("if");
    this.setHelpUrl("");
  },
};

csharpGenerator.forBlock["csharp_logic_if_question"] = function (
  block,
  generator
) {
  // using
  generator.definitions_["import_system"] = "using System;";
  // Parameter
  var comp = generator.valueToCode(block, "comp", Order.NONE);
  var resultA = generator.valueToCode(block, "resultA", Order.NONE);
  var resultB = generator.valueToCode(block, "resultB", Order.NONE);

  // Code
  var codeText = `(${comp}) ? ${resultA} : ${resultB}`;

  return [codeText, Order.NONE];
};

///////////////////////////////////////////////////////////////////
// switch
Blockly.Blocks["csharp_logic_switch"] = {
  init: function () {
    this.appendDummyInput().appendField("switch (");
    this.appendDummyInput();
    this.appendValueInput("compA").setCheck(null);
    this.appendDummyInput().appendField(")");
    this.appendStatementInput("Codes").setCheck(null);

    this.setInputsInline(true);
    this.setPreviousStatement(true, null);
    this.setNextStatement(true, null);
    this.setColour(cs_logic_block_color);
    this.setTooltip("switch");
    this.setHelpUrl("");
  },
};

csharpGenerator.forBlock["csharp_logic_switch"] = function (block, generator) {
  // using
  generator.definitions_["import_system"] = "using System;";
  // Parameter
  var compA = generator.valueToCode(block, "compA", Order.NONE);
  var Codes = generator.statementToCode(block, "Codes");

  // Code
  var codeText = `switch (${compA}){
    ${Codes}
}
`;

  return codeText;
};

///////////////////////////////////////////////////////////////////
// case
Blockly.Blocks["csharp_logic_case"] = {
  init: function () {
    this.appendDummyInput().appendField("case");
    this.appendValueInput("compA").setCheck(null);
    this.appendStatementInput("Codes").setCheck(null);

    this.setInputsInline(true);
    this.setPreviousStatement(true, null);
    this.setNextStatement(true, null);
    this.setColour(cs_logic_block_color);
    this.setTooltip("case");
    this.setHelpUrl("");
  },
};

csharpGenerator.forBlock["csharp_logic_case"] = function (block, generator) {
  // using
  generator.definitions_["import_system"] = "using System;";
  // Parameter
  var compA = generator.valueToCode(block, "compA", Order.NONE);
  var Codes = generator.statementToCode(block, "Codes");

  // Code
  var codeText = `case ${compA}:
{
    ${Codes}
}
`;

  return codeText;
};

///////////////////////////////////////////////////////////////////
// default
Blockly.Blocks["csharp_logic_case_default"] = {
  init: function () {
    this.appendDummyInput().appendField("default");
    this.appendStatementInput("Codes").setCheck(null);

    this.setInputsInline(true);
    this.setPreviousStatement(true, null);
    this.setNextStatement(true, null);
    this.setColour(cs_logic_block_color);
    this.setTooltip("default");
    this.setHelpUrl("");
  },
};

csharpGenerator.forBlock["csharp_logic_case_default"] = function (
  block,
  generator
) {
  // using
  generator.definitions_["import_system"] = "using System;";
  // Parameter
  var Codes = generator.statementToCode(block, "Codes");

  // Code
  var codeText = `default:
{
    ${Codes}
}
`;

  return codeText;
};

///////////////////////////////////////////////////////////////////
// break
Blockly.Blocks["csharp_logic_break"] = {
  init: function () {
    this.appendDummyInput().appendField("break");
    this.setInputsInline(true);
    this.setPreviousStatement(true, null);
    this.setNextStatement(false, null);
    this.setColour(cs_logic_block_color);
    this.setTooltip("break");
    this.setHelpUrl("");
  },
  onchange: function () {},
};

csharpGenerator.forBlock["csharp_logic_break"] = function (block, generator) {
  // using
  generator.definitions_["import_system"] = "using System;";
  // Parameter
  // Code
  var codeText = `break;`;

  return codeText;
};
