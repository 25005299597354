<template>
  <v-app>
    <v-main>
      <!-- <router-view /> -->
      <TitleItem v-if="this.page == 0" @titleProc="emitTitleEvent" />
      <MainItem v-if="this.page == 1" />
    </v-main>
  </v-app>
</template>

<script>
import TitleItem from "./views/TitleView.vue";
import MainItem from "./views/MainView.vue";

export default {
  name: "App",

  components: {
    TitleItem,
    MainItem,
  },

  data() {
    return {
      // 表示ページ番号
      page: 0,
    };
  },

  methods: {
    // メイン画面に遷移
    emitTitleEvent(value) {
      if (value == "move to main") {
        this.page = 1;
      }
    },
  },
  // methods: () => ({}),
};
</script>

<style>
html,
body {
  min-width: 1080px;
  background-color: #ffffff;
}
</style>
