<template>
  <v-container
    fluid
    class="sample"
    style="
      padding: 0px 1px 10px 0px;
      margin: 0px 0px 2px 0px;
      background-color: white;
    "
    elevation="4"
  >
    <v-responsive
      class="sample"
      style="
        padding: 0px 0px 0px 0px;
        margin: 0px 0px 0px 0px;
        /* background-color: orange; */
      "
    >
      <v-row
        class="sample"
        style="
          padding: 0px 0px 10px 1px;
          margin: 1px 1px 10px 1px;
          background-color: white;
        "
      >
        <v-col
          cols="8"
          class="sample"
          fluid
          style="
            padding: 2px 1px 10px 1px;
            margin: 0px 0px 10px 0px;
            background-color: white;
          "
          rounded="lg"
        >
          <v-card
            background-color:pink
            class="sample"
            style="
              padding: 1px 1px 10px 1px;
              margin: 1px 1px 10px 1px;
              background-color: white;
            "
            fluid
            rounded="lg"
            elevation="0"
          >
            <div
              id="blocklyArea"
              ref="blocklyArea"
              style="
                padding: 0px 0px 6px 0px;
                margin: 0px 0px 0px 0px;
                /* background-color: black; */
              "
              class="sample"
              fluid
              rounded="lg"
            >
              <div
                id="blocklyDiv"
                ref="blocklyDiv"
                class="sample"
                style="
                  width: 100%;
                  height: 100%;
                  padding: 0px 0px 0px 0px;
                  margin: 0px 0px 0px 0px;
                  /* background-color: black; */
                "
                fluid
                rounded="lg"
              ></div>
            </div>
          </v-card>
        </v-col>

        <v-col
          cols="4"
          class="sample"
          style="
            padding: 2px 2px 22px 2px;
            margin: 0px 0px 24px 0px;
            background-color: white;
          "
          fluid
          elevation="4"
        >
          <v-card
            class="sample"
            style="
              padding: 1px 1px 1px 1px;
              margin: 1px 1px 1px 1px;
              background-color: orange;
            "
            fluid
            rounded
            title="Code"
            elevation="4"
          >
            <!--
            outlined
            -->
            <div
              class="sample"
              style="
                padding: 1px 1px 10px 1px;
                margin: 1px 1px 1px 1px;
                /* background-color: orange; */
              "
              fluid
              rounded
            >
              <pre
                id="textarea"
                ref="textarea"
                class="pa-1 text-body-2 font-weight-light"
                readonly
                style="
                  width: 100%;
                  height: 100%;
                  background-color: #fffde7;
                  padding: 0px 0px 0px 0px;
                  margin: 0px 0px 0px 0px;
                  resize: none;
                  overflow: scroll;
                "
                fluid
                rounded
              ></pre>
            </div>
          </v-card>
        </v-col>
      </v-row>
    </v-responsive>
  </v-container>
</template>

<!-- <script setup></script> -->
<script setup>
import { onMounted } from "vue";
import * as Blockly from "blockly/core";
import "blockly/blocks";
import "blockly/blockly";
import "blockly/browser";

// import {
//   ContinuousToolbox,
//   ContinuousFlyout,
//   ContinuousMetrics,
// } from "@blockly/continuous-toolbox";

// import "blockly/javascript";
import "blockly/csharp";

import "blockly/blockly_compressed";
import "blockly/blocks_compressed";

// import "blockly/javascript_compressed";
// import { javascriptGenerator } from "blockly/javascript";
import "blockly/csharp_compressed";
import { csharpGenerator } from "blockly/csharp";

import * as JA from "blockly/msg/ja";

import "./../modules/custom_category_es6";
import "./../modules/toolbox_label_es6";

import * as blk_cs_class from "./blocks/class";
import * as blk_cs_logic from "./blocks/logic";
import * as blk_cs_loop from "./blocks/loop";
import * as blk_cs_calc from "./blocks/calc";
import * as blk_cs_console from "./blocks/console";
import * as blk_cs_string from "./blocks/string";
import * as blk_cs_date from "./blocks/dateonly";
import * as blk_cs_time from "./blocks/timeonly";
import * as blk_cs_random from "./blocks/random";
import * as blk_cs_arraylist from "./blocks/arraylist";
import * as blk_cs_list from "./blocks/list";

import swal from "sweetalert2";
import * as beautify from "js-beautify";

var workspace = null;

onMounted(() => {
  const toolbox = `
  <xml xmlns="https://developers.google.com/blockly/xml" id="toolbox-categories" style="display: none; background-color:red;">
    <toolboxlabel name="" colour="#000000"></toolboxlabel>
    ${blk_cs_class.category_classDef}
    ${blk_cs_logic.category_logicDef}
    ${blk_cs_loop.category_loopDef}
    ${blk_cs_calc.category_calcDef}
    ${blk_cs_console.category_consoleDef}
    ${blk_cs_string.category_stringDef}
    ${blk_cs_date.category_dateDef}
    ${blk_cs_time.category_timeDef}
    ${blk_cs_random.category_randomDef}
    ${blk_cs_arraylist.category_arraylistDef}
    ${blk_cs_list.category_listDef}
    <category name="Var" custom="VARIABLE" colour="#FF8A65"></category>
    <category name="Method" custom="PROCEDURE" colour="#FF8A65"></category>
  </xml>
  `;
  // ${blk_cs_math.category_mathDef}

  // 日本語化
  Blockly.setLocale(JA);

  // // 色
  // Blockly.HSV_SATURATION = 0.4;
  // Blockly.HSV_VALUE = 0.8;

  // HATデザイン
  Blockly.BlockSvg.START_HAT = true;
  Blockly.BlockSvg.ADD_START_HATS = true;

  // // 縁取り線
  // Blockly.blockRendering.ConstantProvider.prototype.generateTertiaryColour_ =
  //     (colour) => Blockly.utils.colour.blend('#000', colour, 1.0) || colour;

  workspace = Blockly.inject("blocklyDiv", {
    trashcan: true,
    readOnly: false,
    move: {
      scrollbars: true,
      drag: true,
      wheel: true,
    },
    theme: "Zelos",
    renderer: "zelos",
    style: "logic_blocks",
    horizontalLayout: true,
    // theme: {
    //   blockStyles: {
    //     Core_blocks: {
    //       colourPrimary: "#ff0000",
    //       colourSecondary: "#00ff00",
    //       colourTertiary: "#0000ff",
    //     },
    //   },
    //   categoryStyles: {
    //     Core_category: {
    //       colour: "#ffffff",
    //     },
    //   },
    //   componentStyles: {
    //     workspaceBackgroundColour: "#eeeeee",
    //   },
    //   startHats: true
    // },
    grid: {
      spacing: 10,
      length: 10,
      colour: "#ddd",
      snap: true,
    },
    zoom: {
      controls: true,
      wheel: true,
      startScale: 0.7,
      maxScale: 3,
      minScale: 0.3,
      scaleSpeed: 1.2,
    },
    oneBasedIndex: false,
    toolbox: toolbox,
    // plugins: {
    //   toolbox: ContinuousToolbox,
    //   flyoutsVerticalToolbox: ContinuousFlyout,
    //   metricsManager: ContinuousMetrics,
    // },
  });

  // code generate
  workspace.addChangeListener(async () => {
    const code = csharpGenerator.workspaceToCode(workspace);

    const beautifyOptions = {
      indent_size: "2",
      indent_char: " ",
      max_preserve_newlines: "1",
      preserve_newlines: true,
      keep_array_indentation: false,
      break_chained_methods: false,
      indent_scripts: "normal",
      brace_style: "collapse",
      space_before_conditional: true,
      unescape_strings: false,
      jslint_happy: false,
      end_with_newline: false,
      wrap_line_length: "0",
      indent_inner_html: false,
      comma_first: false,
      e4x: false,
      indent_empty_lines: false,
    };

    const result = beautify.js(code, beautifyOptions);
    document.getElementById("textarea").innerHTML = result;

    // console.log(result);
  });
});
</script>

<script>
export default {
  methods: {
    async newCode() {
      // console.log("=== getNewCode ===");

      // Blockly.serialization.workspaces.save(this.workspace);
      // console.log(state);

      // // 作成ブロックのコードを生成
      // var xml = Blockly.Xml.workspaceToDom(this.workspace);
      // var xml_text = Blockly.Xml.domToText(xml);
      // console.log(xml_text);

      await swal
        .fire({
          title: "新規",
          text: "初期化してもよろしいですか？",
          icon: "info",
          showCancelButton: true,
          confirmButtonText: "OK",
          denyButtonText: "キャンセル",
        })
        .then((result) => {
          if (result.isConfirmed) {
            // 全ブロックを消去
            Blockly.getMainWorkspace().clear();
            document.getElementById("textarea").innerHTML = "";
          } else if (result.isDenied) {
            // console.log("新規 キャンセル");
          }
        });
    },
    stepBack() {
      // console.log("=== stepBack ===");
      // もどす
      try {
        Blockly.getMainWorkspace().undo(false);
      } catch (e) {
        alert(e);
      }
    },
    stepForward() {
      // console.log("=== stepForward ===");
      // すすめる
      try {
        Blockly.getMainWorkspace().undo(true);
      } catch (e) {
        alert(e);
      }
    },
    copyBlocks() {
      // console.log("=== copyBlocks ===");
      // copy
      try {
        // 選択済みブロックを取得
        var selectedBlock = Blockly.getMainWorkspace().selected;
        if (selectedBlock == null) {
          // console.log("selectedBlock is null");
          return;
        }

        // ブロックをコピー
        Blockly.duplicate(Blockly.selected);
      } catch (e) {
        alert(e);
      }
    },
    execCode() {
      // console.log("=== execCode ===");
      let code = document.getElementById("textarea").innerHTML;
      // console.log(code);

      if (code == "") {
        swal.fire({
          title: "実行結果",
          text: "コードが作成されていません。",
          icon: "info",
        });
        return;
      }

      {
        const data = code;

        var xhr = new XMLHttpRequest();
        xhr.withCredentials = true;

        xhr.addEventListener("readystatechange", function () {
          if (this.readyState === 4) {
            if (xhr.status == 200 || xhr.status == 304) {
              // console.log("200:" + this.responseText);

              swal.fire({
                title: "実行結果",
                imageWidth: 680,
                imageHeight: 500,
                html:
                  '<div class="text-box" style="width: 440px;height: 400px;margin: 10px auto; padding: 10px auto;border: 2px solid #999;border-radius: 4px;overflow: scroll;resize: none; text-align: start;">' +
                  '<pre id="message" style="margin: 2px; padding: 2px; text-align: start;">' +
                  this.responseText +
                  "</pre></div>",
              });
            } else {
              // console.log("Failed. HttpStatus: " + xhr.statusText);
            }
          }
        });

        // xhr.open("POST", "https://www.csblocks.somee.com/CsCodeRunner/Execute");
        xhr.open("POST", "https://csblocks.bsite.net/CsCodeRunner/Execute");
        // xhr.open("POST", "http://localhost:55269/CsCodeRunner/Execute");
        xhr.setRequestHeader("Content-Type", "text/plain");

        xhr.send(data);
      }
    },
    async copyCode() {
      // console.log("=== copyCode ===");
      const code = document.getElementById("textarea").innerHTML;
      // クリップボードにコピー
      navigator.clipboard
        .writeText(code)
        .then(() => {
          // 確認メッセージ
          swal.fire({
            title: "コードコピー",
            text: "クリップボードにコードをコピーしました。",
            icon: "info",
          });
        })
        .catch((e) => {
          console.log(e);
          // 確認メッセージ
          swal.fire({
            title: "コードコピー",
            text: "コードのコピーに失敗しました。",
            icon: "error",
          });
        });
    },
  },
};
</script>

<style scoped>
html,
body {
  min-width: 1080px;
  background-color: #eee;
}

.sample {
  height: calc(100vh - 84px);
}
.text-box {
  width: 500px;
  height: 400px;
  margin: 20px auto;
  border: 2px solid #999;
  border-radius: 4px;
  overflow: scroll;
  resize: none;
}

.text-box pre {
  margin: 0;
  padding: 10px;
  /* font-family: sans-serif; */
  font-size: 16px;
  white-space: pre-wrap;
}

.text-box::-webkit-scrollbar {
  width: 10px;
  height: 10px;
}

.text-box::-webkit-scrollbar-thumb {
  background: #ccc;
  border-radius: 5px;
}

.text-box::-webkit-scrollbar-track {
  background: #f5f5f5;
}

.toolboxBackgroundColour {
  fill: green;
}
</style>
