import * as Blockly from "blockly/core";
import { csharpGenerator, Order } from "blockly/csharp";
import * as BlockColor from "./const.js";

// ブロックの配色
var cs_loop_block_color = BlockColor.cs_block_color;

// Timerブロック定義
const category_loopDef = `
    <category name="Loop" colour="${cs_loop_block_color}">
    <block type="csharp_loop_for">
      <value name="startNo">
        <block type="math_number">
          <field name="NUM">0</field>
        </block>
      </value>
      <value name="finNo">
        <block type="math_number">
          <field name="NUM">10</field>
        </block>
      </value>
    </block>
    <block type="csharp_loop_for_full">
      <value name="startNo">
        <block type="math_number">
          <field name="NUM">0</field>
        </block>
      </value>
      <value name="finNo">
        <block type="math_number">
          <field name="NUM">10</field>
        </block>
      </value>
      <value name="stepNo">
        <block type="math_number">
          <field name="NUM">0</field>
        </block>
      </value>
    </block>
    <block type="csharp_loop_while">
    </block>
    <block type="csharp_loop_foreach">
    </block>
    <block type="csharp_loop_break_continue">
    </block>
    </category>`;

export { category_loopDef };

///////////////////////////////////////////////////////////////////
// for
Blockly.Blocks["csharp_loop_for"] = {
  init: function () {
    this.appendDummyInput()
      .appendField("for (var ")
      .appendField(new Blockly.FieldVariable("i"), "varName")
      .appendField("=");
    this.appendValueInput("startNo").setCheck("Number");
    this.appendDummyInput().appendField(";");
    this.appendDummyInput().appendField(
      new Blockly.FieldLabelSerializable("i"),
      "varName0"
    );
    this.appendDummyInput().appendField("<");
    this.appendValueInput("finNo").setCheck("Number");
    this.appendDummyInput().appendField(";");
    this.appendDummyInput().appendField(
      new Blockly.FieldLabelSerializable("i"),
      "varName1"
    );
    this.appendDummyInput().appendField("++)");

    this.appendStatementInput("Codes").setCheck(null);
    this.setInputsInline(true);
    this.setPreviousStatement(true, null);
    this.setNextStatement(true, null);
    this.setColour(cs_loop_block_color);
    this.setTooltip("for");
    this.setHelpUrl("");
  },
  onchange: function () {},
};

csharpGenerator.forBlock["csharp_loop_for"] = function (block, generator) {
  // using
  generator.definitions_["import_system"] = "using System;";
  // Parameter
  var varName = generator.getVariableName(block.getFieldValue("varName"));
  var startNo = generator.valueToCode(block, "startNo", Order.NONE);
  var finNo = generator.valueToCode(block, "finNo", Order.NONE);
  // var calculation = block.getFieldValue("calculation");
  var Codes = generator.statementToCode(block, "Codes");

  // update
  block.setFieldValue(varName, "varName0");
  block.setFieldValue(varName, "varName1");

  // Code
  var codeText = `for (var ${varName}=${startNo}; ${varName}<${finNo}; ${varName}++){
    ${Codes}
}`;

  return codeText;
};

///////////////////////////////////////////////////////////////////
// for
Blockly.Blocks["csharp_loop_for_full"] = {
  init: function () {
    this.appendDummyInput()
      .appendField("for (var ")
      .appendField(new Blockly.FieldVariable("i"), "varName")
      .appendField("=");
    this.appendValueInput("startNo").setCheck("Number");
    this.appendDummyInput().appendField(";");
    this.appendDummyInput().appendField(
      new Blockly.FieldLabelSerializable("i"),
      "varName0"
    );
    this.appendDummyInput().appendField(
      new Blockly.FieldDropdown([
        ["<", "<"],
        [">", ">"],
        ["<=", "<="],
        [">=", ">="],
      ]),
      "lessThanSign"
    );
    this.appendValueInput("finNo").setCheck("Number");
    this.appendDummyInput().appendField(";");
    this.appendDummyInput().appendField(
      new Blockly.FieldLabelSerializable("i"),
      "varName1"
    );
    this.appendDummyInput().appendField(
      new Blockly.FieldDropdown([
        ["++", "++"],
        ["--", "--"],
        ["+=", "+="],
        ["-=", "-="],
      ]),
      "calculation"
    );
    this.appendValueInput("stepNo").setCheck("Number");
    this.appendDummyInput().appendField(")");

    this.appendStatementInput("Codes").setCheck(null);
    this.setInputsInline(true);
    this.setPreviousStatement(true, null);
    this.setNextStatement(true, null);
    this.setColour(cs_loop_block_color);
    this.setTooltip("for");
    this.setHelpUrl("");
  },
  onchange: function () {},
};

csharpGenerator.forBlock["csharp_loop_for_full"] = function (block, generator) {
  // using
  generator.definitions_["import_system"] = "using System;";
  // Parameter
  var varName = generator.getVariableName(block.getFieldValue("varName"));
  var startNo = generator.valueToCode(block, "startNo", Order.NONE);
  var lessThanSign = block.getFieldValue("lessThanSign");
  var finNo = generator.valueToCode(block, "finNo", Order.NONE);
  var calculation = block.getFieldValue("calculation");
  var stepNo = generator.valueToCode(block, "stepNo", Order.NONE);

  var Codes = generator.statementToCode(block, "Codes");

  // update
  block.setFieldValue(varName, "varName0");
  block.setFieldValue(varName, "varName1");

  // Code
  var codeText = "";
  if (calculation == "++" || calculation == "--") {
    codeText = `for (var ${varName}=${startNo}; ${varName}${lessThanSign}${finNo}; ${varName}${calculation}){
    ${Codes}
}`;
  } else {
    codeText = `for (var ${varName}=${startNo}; ${varName}${lessThanSign}${finNo}; ${varName}${calculation}${stepNo}){
    ${Codes}
}`;
  }

  return codeText;
};

///////////////////////////////////////////////////////////////////
// while
Blockly.Blocks["csharp_loop_while"] = {
  init: function () {
    this.appendDummyInput().appendField("while (");
    this.appendValueInput("compA").setCheck("Boolean");
    this.appendDummyInput().appendField(")");

    this.appendStatementInput("Codes").setCheck(null);
    this.setInputsInline(true);
    this.setPreviousStatement(true, null);
    this.setNextStatement(true, null);
    this.setColour(cs_loop_block_color);
    this.setTooltip("while");
    this.setHelpUrl("");
  },
  onchange: function () {},
};

csharpGenerator.forBlock["csharp_loop_while"] = function (block, generator) {
  // using
  generator.definitions_["import_system"] = "using System;";
  // Parameter
  var compA = generator.valueToCode(block, "compA", Order.NONE);
  var Codes = generator.statementToCode(block, "Codes");

  // Code
  var codeText = `while (${compA}){
    ${Codes}
}`;

  return codeText;
};

///////////////////////////////////////////////////////////////////
// foreach
Blockly.Blocks["csharp_loop_foreach"] = {
  init: function () {
    this.appendDummyInput()
      .appendField("foreach (var")
      .appendField(new Blockly.FieldVariable("o"), "varName")
      .appendField(" in ");
    this.appendValueInput("sourceVar").setCheck(null);
    this.appendDummyInput().appendField(")");

    this.appendStatementInput("Codes").setCheck(null);
    this.setInputsInline(true);
    this.setPreviousStatement(true, null);
    this.setNextStatement(true, null);
    this.setColour(cs_loop_block_color);
    this.setTooltip("foreach");
    this.setHelpUrl("");
  },
  onchange: function () {},
};

csharpGenerator.forBlock["csharp_loop_foreach"] = function (block, generator) {
  // using
  generator.definitions_["import_system"] = "using System;";
  // Parameter
  var oneVarName = generator.getVariableName(block.getFieldValue("varName"));
  var sourceVarName = generator.valueToCode(block, "sourceVar", Order.NONE);
  var Codes = generator.statementToCode(block, "Codes");

  // Code
  var codeText = `foreach (var ${oneVarName} in ${sourceVarName}){
    ${Codes}
}`;

  return codeText;
};

///////////////////////////////////////////////////////////////////
// break continue
Blockly.Blocks["csharp_loop_break_continue"] = {
  init: function () {
    this.appendDummyInput().appendField(
      new Blockly.FieldDropdown([
        ["break", "break"],
        ["continue", "continue"],
      ]),
      "break_continue"
    );

    this.setInputsInline(true);
    this.setPreviousStatement(true, null);
    this.setNextStatement(false, null);
    this.setColour(cs_loop_block_color);
    this.setTooltip("break_continue");
    this.setHelpUrl("");
  },
  onchange: function () {},
};

csharpGenerator.forBlock["csharp_loop_break_continue"] = function (
  block,
  generator
) {
  // using
  generator.definitions_["import_system"] = "using System;";
  // Parameter
  var break_continue = block.getFieldValue("break_continue");

  // Code
  var codeText = `${break_continue};`;

  return codeText;
};
