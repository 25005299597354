<template>
  <v-overlay v-model="overlay">
    <v-container
      fluid
      style="
        background-color: black;
        padding: 0px 0px 0px 0px;
        margin: 5vh 0px 0px 5vw;
        width: 90vw;
        height: 90vh;
        align-content: center;
        vertical-align: middle;
      "
    >
      <v-responsive
        style="padding: 0px 0px 0px 0px; margin: 0px 0px 0px 0px; height: 90vh"
      >
        <v-row
          style="
            padding: 2px 2px 2px 2px;
            margin: 0px 0px 0px 0px;
            height: 60vh;
          "
        >
          <v-col
            cols="12"
            fluid
            style="
              padding: 0px 0px 0px 0px;
              margin: 0px 0px 0px 0px;
              height: 60vh;
            "
          >
            <v-card
              elevation="2"
              style="
                height: 60vh;
                background-color: black;
                padding: 0px 0px 0px 0px;
                margin: 0px 0px 0px 0px;
              "
              fluid
            >
              <div
                style="
                  padding: 0px 0px 4px 0px;
                  margin: 0px 0px 0px 0px;
                  height: 60vh;
                "
                fluid
              >
                <textarea
                  id="textareaResult"
                  ref="textareaResult"
                  class="pa-1"
                  placeholder="実行結果"
                  readonly
                  style="
                    width: 100%;
                    height: 100%;
                    background-color: #eeeeee;
                    padding: 0px 0px 0px 0px;
                    margin: 0px 0px 0px 0px;
                    resize: none;
                    overflow: scroll;
                  "
                  fluid
                ></textarea>
              </div>
            </v-card>
          </v-col>
        </v-row>

        <v-row
          style="
            padding: 0px 2px 2px 2px;
            margin: 0px 0px 0px 0px;
            height: 30vh;
            background-color: black;
          "
        >
          <v-col
            cols="12"
            style="
              padding: 0px 0px 0px 0px;
              margin: 0px 0px 0px 0px;
              height: 30vh;
            "
            fluid
          >
            <v-card
              elevation="2"
              style="
                padding: 0px 0px 0px 0px;
                margin: 0px 0px 0px 0px;
                height: 30vh;
                background-color: black;
              "
              fluid
            >
              <div
                style="
                  height: 30vh;
                  padding: 0px 0px 2px 0px;
                  margin: 0px 0px 0px 0px;
                "
                fluid
              >
                <textarea
                  id="textareaDebug"
                  ref="textareaDebug"
                  class="pa-1"
                  placeholder="デバッグ"
                  readonly
                  style="
                    width: 100%;
                    height: 100%;
                    background-color: #eeeeee;
                    padding: 0px 0px 0px 0px;
                    margin: 0px 0px 0px 0px;
                    resize: none;
                    overflow: scroll;
                  "
                  fluid
                ></textarea>
              </div>
            </v-card>
          </v-col>
        </v-row>
      </v-responsive>
    </v-container>
  </v-overlay>
</template>

<script setup>
var overlay = false;
</script>

<style scoped></style>
