import * as Blockly from "blockly/core";
import { csharpGenerator, Order } from "blockly/csharp";
import * as BlockColor from "./const.js";

// ブロックの配色
var cs_time_block_color = BlockColor.cs_block_color;

// Timerブロック定義
const category_timeDef = `
    <category name="Time" colour="${cs_time_block_color}">
    <block type="csharp_time_new">
      <value name="hour">
        <block type="math_number">
          <field name="NUM">0</field>
        </block>
      </value>
      <value name="minute">
        <block type="math_number">
          <field name="NUM">0</field>
        </block>
      </value>
      <value name="second">
        <block type="math_number">
          <field name="NUM">0</field>
        </block>
      </value>
    </block>
    <block type="csharp_time_hour"></block>
    <block type="csharp_time_millisecond"></block>
    <block type="csharp_time_minute"></block>
    <block type="csharp_time_second"></block>
    <block type="csharp_time_ticks"></block>
    <block type="csharp_time_addhours">
      <value name="hour">
        <block type="math_number">
          <field name="NUM">0</field>
        </block>
      </value>
    </block>
    <block type="csharp_time_addminutes">
      <value name="minute">
        <block type="math_number">
          <field name="NUM">0</field>
        </block>
      </value>
    </block>
    <block type="csharp_time_tolongtimestring"></block>
    <block type="csharp_time_toshorttimestring"></block>
    <block type="csharp_time_tostring"></block>

    </category>`;

export { category_timeDef };

///////////////////////////////////////////////////////////////////
// new
Blockly.Blocks["csharp_time_new"] = {
  init: function () {
    this.appendDummyInput().appendField("new TimeOnly(");
    this.appendValueInput("hour").setCheck("Number");
    this.appendDummyInput().appendField(",");
    this.appendValueInput("minute").setCheck("Number");
    this.appendDummyInput().appendField(",");
    this.appendValueInput("second").setCheck("Number");
    this.appendDummyInput().appendField(")");

    this.setInputsInline(true);
    this.setPreviousStatement(false, null);
    this.setNextStatement(false, null);
    this.setOutput(true, "TimeOnly");
    this.setColour(cs_time_block_color);
    this.setTooltip("TimeOnly");
    this.setHelpUrl("");
  },
};

csharpGenerator.forBlock["csharp_time_new"] = function (block, generator) {
  // using
  generator.definitions_["import_system"] = "using System;";
  // Parameter
  var compA = generator.valueToCode(block, "hour", Order.NONE);
  var compB = generator.valueToCode(block, "minute", Order.NONE);
  var compC = generator.valueToCode(block, "second", Order.NONE);
  // Code
  var codeText = `new TimeOnly(${compA},${compB},${compC})`;

  return [codeText, Order.NONE];
};

///////////////////////////////////////////////////////////////////
// hour
Blockly.Blocks["csharp_time_hour"] = {
  init: function () {
    this.appendValueInput("compA").setCheck(null);
    this.appendDummyInput().appendField(".Hour");

    this.setInputsInline(true);
    this.setPreviousStatement(false, null);
    this.setNextStatement(false, null);
    this.setOutput(true, "Hour");
    this.setColour(cs_time_block_color);
    this.setTooltip("Hour");
    this.setHelpUrl("");
  },
};

csharpGenerator.forBlock["csharp_time_hour"] = function (block, generator) {
  // using
  generator.definitions_["import_system"] = "using System;";
  // Parameter
  var compA = generator.valueToCode(block, "compA", Order.NONE);
  // Code
  var codeText = `${compA}.Hour`;

  return [codeText, Order.NONE];
};

///////////////////////////////////////////////////////////////////
// millsecond
Blockly.Blocks["csharp_time_millisecond"] = {
  init: function () {
    this.appendValueInput("compA").setCheck(null);
    this.appendDummyInput().appendField(".Millisecond");

    this.setInputsInline(true);
    this.setPreviousStatement(false, null);
    this.setNextStatement(false, null);
    this.setOutput(true, "Millisecond");
    this.setColour(cs_time_block_color);
    this.setTooltip("Millisecond");
    this.setHelpUrl("");
  },
};

csharpGenerator.forBlock["csharp_time_millisecond"] = function (
  block,
  generator
) {
  // using
  generator.definitions_["import_system"] = "using System;";
  // Parameter
  var compA = generator.valueToCode(block, "compA", Order.NONE);
  // Code
  var codeText = `${compA}.Millisecond`;

  return [codeText, Order.NONE];
};

///////////////////////////////////////////////////////////////////
// minute
Blockly.Blocks["csharp_time_minute"] = {
  init: function () {
    this.appendValueInput("compA").setCheck(null);
    this.appendDummyInput().appendField(".Minute");

    this.setInputsInline(true);
    this.setPreviousStatement(false, null);
    this.setNextStatement(false, null);
    this.setOutput(true, "Minute");
    this.setColour(cs_time_block_color);
    this.setTooltip("Minute");
    this.setHelpUrl("");
  },
};

csharpGenerator.forBlock["csharp_time_minute"] = function (block, generator) {
  // using
  generator.definitions_["import_system"] = "using System;";
  // Parameter
  var compA = generator.valueToCode(block, "compA", Order.NONE);
  // Code
  var codeText = `${compA}.Minute`;

  return [codeText, Order.NONE];
};

///////////////////////////////////////////////////////////////////
// dayofyear
Blockly.Blocks["csharp_time_second"] = {
  init: function () {
    this.appendValueInput("compA").setCheck(null);
    this.appendDummyInput().appendField(".Second");

    this.setInputsInline(true);
    this.setPreviousStatement(false, null);
    this.setNextStatement(false, null);
    this.setOutput(true, "Second");
    this.setColour(cs_time_block_color);
    this.setTooltip("Second");
    this.setHelpUrl("");
  },
};

csharpGenerator.forBlock["csharp_time_second"] = function (block, generator) {
  // using
  generator.definitions_["import_system"] = "using System;";
  // Parameter
  var compA = generator.valueToCode(block, "compA", Order.NONE);
  // Code
  var codeText = `${compA}.Second`;

  return [codeText, Order.NONE];
};

///////////////////////////////////////////////////////////////////
// ticks
Blockly.Blocks["csharp_time_ticks"] = {
  init: function () {
    this.appendValueInput("compA").setCheck(null);
    this.appendDummyInput().appendField(".Ticks");

    this.setInputsInline(true);
    this.setPreviousStatement(false, null);
    this.setNextStatement(false, null);
    this.setOutput(true, "Ticks");
    this.setColour(cs_time_block_color);
    this.setTooltip("Ticks");
    this.setHelpUrl("");
  },
};

csharpGenerator.forBlock["csharp_time_ticks"] = function (block, generator) {
  // using
  generator.definitions_["import_system"] = "using System;";
  // Parameter
  var compA = generator.valueToCode(block, "compA", Order.NONE);
  // Code
  var codeText = `${compA}.Ticks`;

  return [codeText, Order.NONE];
};

///////////////////////////////////////////////////////////////////
// addhours
Blockly.Blocks["csharp_time_addhours"] = {
  init: function () {
    this.appendValueInput("compA").setCheck(null);
    this.appendDummyInput().appendField(".AddHours(");
    this.appendValueInput("hour").setCheck("Number");
    this.appendDummyInput().appendField(")");

    this.setInputsInline(true);
    this.setPreviousStatement(false, null);
    this.setNextStatement(false, null);
    this.setOutput(true, "AddHours");
    this.setColour(cs_time_block_color);
    this.setTooltip("AddHours");
    this.setHelpUrl("");
  },
};

csharpGenerator.forBlock["csharp_time_addhours"] = function (block, generator) {
  // using
  generator.definitions_["import_system"] = "using System;";
  // Parameter
  var compA = generator.valueToCode(block, "compA", Order.NONE);
  var compB = generator.valueToCode(block, "hour", Order.NONE);
  // Code
  var codeText = `${compA}.AddHours(${compB})`;

  return [codeText, Order.NONE];
};

///////////////////////////////////////////////////////////////////
// addminutes
Blockly.Blocks["csharp_time_addminutes"] = {
  init: function () {
    this.appendValueInput("compA").setCheck(null);
    this.appendDummyInput().appendField(".AddMinutes(");
    this.appendValueInput("minute").setCheck("Number");
    this.appendDummyInput().appendField(")");

    this.setInputsInline(true);
    this.setPreviousStatement(false, null);
    this.setNextStatement(false, null);
    this.setOutput(true, "AddMinutes");
    this.setColour(cs_time_block_color);
    this.setTooltip("AddMinutes");
    this.setHelpUrl("");
  },
};

csharpGenerator.forBlock["csharp_time_addminutes"] = function (
  block,
  generator
) {
  // using
  generator.definitions_["import_system"] = "using System;";
  // Parameter
  var compA = generator.valueToCode(block, "compA", Order.NONE);
  var compB = generator.valueToCode(block, "minute", Order.NONE);
  // Code
  var codeText = `${compA}.AddMinutes(${compB})`;

  return [codeText, Order.NONE];
};

///////////////////////////////////////////////////////////////////
// tolongtimestring
Blockly.Blocks["csharp_time_tolongtimestring"] = {
  init: function () {
    this.appendValueInput("compA").setCheck(null);
    this.appendDummyInput().appendField(".ToLongTimeString()");

    this.setInputsInline(true);
    this.setPreviousStatement(false, null);
    this.setNextStatement(false, null);
    this.setOutput(true, "ToLongTimeString");
    this.setColour(cs_time_block_color);
    this.setTooltip("ToLongTimeString");
    this.setHelpUrl("");
  },
};

csharpGenerator.forBlock["csharp_time_tolongtimestring"] = function (
  block,
  generator
) {
  // using
  generator.definitions_["import_system"] = "using System;";
  // Parameter
  var compA = generator.valueToCode(block, "compA", Order.NONE);
  // Code
  var codeText = `${compA}.ToLongTimeString()`;

  return [codeText, Order.NONE];
};

///////////////////////////////////////////////////////////////////
// toshorttimestring
Blockly.Blocks["csharp_time_toshorttimestring"] = {
  init: function () {
    this.appendValueInput("compA").setCheck(null);
    this.appendDummyInput().appendField(".ToShortTimeString()");

    this.setInputsInline(true);
    this.setPreviousStatement(false, null);
    this.setNextStatement(false, null);
    this.setOutput(true, "ToShortTimeString");
    this.setColour(cs_time_block_color);
    this.setTooltip("ToShortTimeString");
    this.setHelpUrl("");
  },
};

csharpGenerator.forBlock["csharp_time_toshorttimestring"] = function (
  block,
  generator
) {
  // using
  generator.definitions_["import_system"] = "using System;";
  // Parameter
  var compA = generator.valueToCode(block, "compA", Order.NONE);
  // Code
  var codeText = `${compA}.ToShortTimeString()`;

  return [codeText, Order.NONE];
};

///////////////////////////////////////////////////////////////////
// tostring
Blockly.Blocks["csharp_time_tostring"] = {
  init: function () {
    this.appendValueInput("compA").setCheck(null);
    this.appendDummyInput().appendField(".ToString()");

    this.setInputsInline(true);
    this.setPreviousStatement(false, null);
    this.setNextStatement(false, null);
    this.setOutput(true, "ToString");
    this.setColour(cs_time_block_color);
    this.setTooltip("ToString");
    this.setHelpUrl("");
  },
};

csharpGenerator.forBlock["csharp_time_tostring"] = function (block, generator) {
  // using
  generator.definitions_["import_system"] = "using System;";
  // Parameter
  var compA = generator.valueToCode(block, "compA", Order.NONE);
  // Code
  var codeText = `${compA}.ToString()`;

  return [codeText, Order.NONE];
};
