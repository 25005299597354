import * as Blockly from "blockly/core";
import { csharpGenerator, Order } from "blockly/csharp";
import * as BlockColor from "./const.js";

// ブロックの配色
var cs_console_block_color = BlockColor.cs_block_color;

// Timerブロック定義
const category_consoleDef = `
    <category name="Console" colour="${cs_console_block_color}">
    <block type="csharp_console_write"></block>
    <block type="csharp_console_writeif"></block>
    <block type="csharp_console_writeline"></block>
    <block type="csharp_console_writelineif"></block>
    </category>`;

export { category_consoleDef };

///////////////////////////////////////////////////////////////////
// Write()
Blockly.Blocks["csharp_console_write"] = {
  init: function () {
    this.appendDummyInput().appendField("console.Write(");
    this.appendValueInput("varA").setCheck(null);
    this.appendDummyInput().appendField(")");

    this.setInputsInline(true);
    this.setPreviousStatement(true, null);
    this.setNextStatement(true, null);
    this.setColour(cs_console_block_color);
    this.setTooltip("Write");
    this.setHelpUrl("");
  },
  onchange: function () {},
};

csharpGenerator.forBlock["csharp_console_write"] = function (block, generator) {
  // using
  generator.definitions_["import_system"] = "using System;";

  // Parameter
  var varA = generator.valueToCode(block, "varA", Order.NONE);

  // update

  // Code
  var codeText = `Console.Write(${varA});\n`;

  return codeText;
};

///////////////////////////////////////////////////////////////////
// Write()
Blockly.Blocks["csharp_console_writeif"] = {
  init: function () {
    this.appendDummyInput().appendField("console.WriteIf(");
    this.appendValueInput("compA").setCheck("Boolean");
    this.appendDummyInput().appendField(",");
    this.appendValueInput("varA").setCheck(null);
    this.appendDummyInput().appendField(")");

    this.setInputsInline(true);
    this.setPreviousStatement(true, null);
    this.setNextStatement(true, null);
    this.setColour(cs_console_block_color);
    this.setTooltip("WriteIf");
    this.setHelpUrl("");
  },
  onchange: function () {},
};

csharpGenerator.forBlock["csharp_console_writeif"] = function (
  block,
  generator
) {
  // using
  generator.definitions_["import_system"] = "using System;";

  // Parameter
  var compA = generator.valueToCode(block, "compA", Order.NONE);
  var varA = generator.valueToCode(block, "varA", Order.NONE);

  // update

  // Code
  var codeText = `Console.WriteIf(${compA}, ${varA});\n`;

  return codeText;
};

///////////////////////////////////////////////////////////////////
// WriteLine()
Blockly.Blocks["csharp_console_writeline"] = {
  init: function () {
    this.appendDummyInput().appendField("console.WriteLine(");
    this.appendValueInput("varA").setCheck(null);
    this.appendDummyInput().appendField(")");

    this.setInputsInline(true);
    this.setPreviousStatement(true, null);
    this.setNextStatement(true, null);
    this.setColour(cs_console_block_color);
    this.setTooltip("WriteLine");
    this.setHelpUrl("");
  },
  onchange: function () {},
};

csharpGenerator.forBlock["csharp_console_writeline"] = function (
  block,
  generator
) {
  // using
  generator.definitions_["import_system"] = "using System;";

  // Parameter
  var varA = generator.valueToCode(block, "varA", Order.NONE);

  // update

  // Code
  var codeText = `Console.WriteLine(${varA});\n`;

  return codeText;
};

///////////////////////////////////////////////////////////////////
// WriteLineIf()
Blockly.Blocks["csharp_console_writelineif"] = {
  init: function () {
    this.appendDummyInput().appendField("console.WriteLineIf(");
    this.appendValueInput("compA").setCheck("Boolean");
    this.appendDummyInput().appendField(",");
    this.appendValueInput("varA").setCheck(null);
    this.appendDummyInput().appendField(")");

    this.setInputsInline(true);
    this.setPreviousStatement(true, null);
    this.setNextStatement(true, null);
    this.setColour(cs_console_block_color);
    this.setTooltip("WriteLineIf");
    this.setHelpUrl("");
  },
  onchange: function () {},
};

csharpGenerator.forBlock["csharp_console_writelineif"] = function (
  block,
  generator
) {
  // using
  generator.definitions_["import_system"] = "using System;";

  // Parameter
  var compA = generator.valueToCode(block, "compA", Order.NONE);
  var varA = generator.valueToCode(block, "varA", Order.NONE);

  // update

  // Code
  var codeText = `Console.WriteLineIf(${compA}, ${varA});\n`;

  return codeText;
};
