import * as Blockly from "blockly/core";
import { csharpGenerator, Order } from "blockly/csharp";
import * as BlockColor from "./const.js";

// ブロックの配色
// var cs_arraylist_block_color = "#FFD54F";
var cs_arraylist_block_color = BlockColor.cs_block_color;

// Timerブロック定義
const category_arraylistDef = `
    <category name="ArrayList" colour="${cs_arraylist_block_color}">
    <block type="csharp_arraylist_new"></block>
    <block type="csharp_arraylist_count"></block>
    <block type="csharp_arraylist_item">
      <value name="index">
        <block type="math_number">
          <field name="NUM">0</field>
        </block>
      </value>
    </block>
    <block type="csharp_arraylist_add"></block>
    <block type="csharp_arraylist_indexof"></block>
    <block type="csharp_arraylist_insert"></block>
    <block type="csharp_arraylist_lastindexof"></block>
    <block type="csharp_arraylist_remove"></block>
    <block type="csharp_arraylist_removeat">
      <value name="index">
        <block type="math_number">
          <field name="NUM">0</field>
        </block>
      </value>
    </block>
    <block type="csharp_arraylist_reverse"></block>
    <block type="csharp_arraylist_sort"></block>
    <block type="csharp_arraylist_tostring"></block>

    </category>`;

export { category_arraylistDef };

///////////////////////////////////////////////////////////////////
// new
Blockly.Blocks["csharp_arraylist_new"] = {
  init: function () {
    this.appendDummyInput().appendField("new ArrayList()");

    this.setInputsInline(true);
    this.setPreviousStatement(false, null);
    this.setNextStatement(false, null);
    this.setOutput(true, "ArrayList");
    this.setColour(cs_arraylist_block_color);
    this.setTooltip("ArrayList");
    this.setHelpUrl("");
  },
};

csharpGenerator.forBlock["csharp_arraylist_new"] = function (block, generator) {
  // using
  generator.definitions_["import_system"] = "using System;";
  generator.definitions_["import_collections"] = "using System.Collections;";
  // Parameter
  // Code
  var codeText = `new ArrayList()`;

  return [codeText, Order.NONE];
};

///////////////////////////////////////////////////////////////////
// count
Blockly.Blocks["csharp_arraylist_count"] = {
  init: function () {
    this.appendValueInput("compA").setCheck(null);
    this.appendDummyInput().appendField(".Count");

    this.setInputsInline(true);
    this.setPreviousStatement(false, null);
    this.setNextStatement(false, null);
    this.setOutput(true, "Count");
    this.setColour(cs_arraylist_block_color);
    this.setTooltip("Count");
    this.setHelpUrl("");
  },
};

csharpGenerator.forBlock["csharp_arraylist_count"] = function (
  block,
  generator
) {
  // using
  generator.definitions_["import_system"] = "using System;";
  generator.definitions_["import_collections"] = "using System.Collections;";
  // Parameter
  var compA = generator.valueToCode(block, "compA", Order.NONE);
  // Code
  var codeText = `${compA}.Count`;

  return [codeText, Order.NONE];
};

///////////////////////////////////////////////////////////////////
// item
Blockly.Blocks["csharp_arraylist_item"] = {
  init: function () {
    this.appendValueInput("compA").setCheck(null);
    this.appendDummyInput().appendField(".item[");
    this.appendValueInput("index").setCheck("Number");
    this.appendDummyInput().appendField("]");

    this.setInputsInline(true);
    this.setPreviousStatement(false, null);
    this.setNextStatement(false, null);
    this.setOutput(true, "Item");
    this.setColour(cs_arraylist_block_color);
    this.setTooltip("Item");
    this.setHelpUrl("");
  },
};

csharpGenerator.forBlock["csharp_arraylist_item"] = function (
  block,
  generator
) {
  // using
  generator.definitions_["import_system"] = "using System;";
  generator.definitions_["import_collections"] = "using System.Collections;";
  // Parameter
  var compA = generator.valueToCode(block, "compA", Order.NONE);
  var compB = generator.valueToCode(block, "index", Order.NONE);
  // Code
  var codeText = `${compA}[${compB}]`;

  return [codeText, Order.NONE];
};

///////////////////////////////////////////////////////////////////
// add
Blockly.Blocks["csharp_arraylist_add"] = {
  init: function () {
    this.appendValueInput("compA").setCheck(null);
    this.appendDummyInput().appendField(".Add(");
    this.appendValueInput("compB").setCheck(null);
    this.appendDummyInput().appendField(")");

    this.setInputsInline(true);
    this.setPreviousStatement(true, null);
    this.setNextStatement(true, null);
    this.setColour(cs_arraylist_block_color);
    this.setTooltip("Add");
    this.setHelpUrl("");
  },
};

csharpGenerator.forBlock["csharp_arraylist_add"] = function (block, generator) {
  // using
  generator.definitions_["import_system"] = "using System;";
  generator.definitions_["import_collections"] = "using System.Collections;";
  // Parameter
  var compA = generator.valueToCode(block, "compA", Order.NONE);
  var compB = generator.valueToCode(block, "compB", Order.NONE);
  // Code
  var codeText = `${compA}.Add(${compB});\n`;

  return codeText;
};

///////////////////////////////////////////////////////////////////
// indexof
Blockly.Blocks["csharp_arraylist_indexof"] = {
  init: function () {
    this.appendValueInput("compA").setCheck(null);
    this.appendDummyInput().appendField(".IndexOf(");
    this.appendValueInput("compB").setCheck(null);
    this.appendDummyInput().appendField(")");

    this.setInputsInline(true);
    this.setPreviousStatement(false, null);
    this.setNextStatement(false, null);
    this.setOutput(true, "IndexOf");
    this.setColour(cs_arraylist_block_color);
    this.setTooltip("IndexOf");
    this.setHelpUrl("");
  },
};

csharpGenerator.forBlock["csharp_arraylist_indexof"] = function (
  block,
  generator
) {
  // using
  generator.definitions_["import_system"] = "using System;";
  generator.definitions_["import_collections"] = "using System.Collections;";
  // Parameter
  var compA = generator.valueToCode(block, "compA", Order.NONE);
  var compB = generator.valueToCode(block, "compB", Order.NONE);
  // Code
  var codeText = `${compA}.IndexOf(${compB})`;

  return [codeText, Order.NONE];
};

///////////////////////////////////////////////////////////////////
// insert
Blockly.Blocks["csharp_arraylist_insert"] = {
  init: function () {
    this.appendValueInput("compA").setCheck(null);
    this.appendDummyInput().appendField(".Insert(");
    this.appendValueInput("compB").setCheck(null);
    this.appendDummyInput().appendField(")");

    this.setInputsInline(true);
    this.setPreviousStatement(true, null);
    this.setNextStatement(true, null);
    this.setColour(cs_arraylist_block_color);
    this.setTooltip("Insert");
    this.setHelpUrl("");
  },
};

csharpGenerator.forBlock["csharp_arraylist_insert"] = function (
  block,
  generator
) {
  // using
  generator.definitions_["import_system"] = "using System;";
  generator.definitions_["import_collections"] = "using System.Collections;";
  // Parameter
  var compA = generator.valueToCode(block, "compA", Order.NONE);
  var compB = generator.valueToCode(block, "compB", Order.NONE);
  // Code
  var codeText = `${compA}.Insert(${compB})`;

  return codeText;
};

///////////////////////////////////////////////////////////////////
// lastindexof
Blockly.Blocks["csharp_arraylist_lastindexof"] = {
  init: function () {
    this.appendValueInput("compA").setCheck(null);
    this.appendDummyInput().appendField(".LastIndexOf(");
    this.appendValueInput("compB").setCheck(null);
    this.appendDummyInput().appendField(")");

    this.setInputsInline(true);
    this.setPreviousStatement(false, null);
    this.setNextStatement(false, null);
    this.setOutput(true, "LastIndexOf");
    this.setColour(cs_arraylist_block_color);
    this.setTooltip("LastIndexOf");
    this.setHelpUrl("");
  },
};

csharpGenerator.forBlock["csharp_arraylist_lastindexof"] = function (
  block,
  generator
) {
  // using
  generator.definitions_["import_system"] = "using System;";
  generator.definitions_["import_collections"] = "using System.Collections;";
  // Parameter
  var compA = generator.valueToCode(block, "compA", Order.NONE);
  var compB = generator.valueToCode(block, "compB", Order.NONE);
  // Code
  var codeText = `${compA}.LastIndexOf(${compB})`;

  return [codeText, Order.NONE];
};

///////////////////////////////////////////////////////////////////
// remove
Blockly.Blocks["csharp_arraylist_remove"] = {
  init: function () {
    this.appendValueInput("compA").setCheck(null);
    this.appendDummyInput().appendField(".Remove(");
    this.appendValueInput("compB").setCheck(null);
    this.appendDummyInput().appendField(")");

    this.setInputsInline(true);
    this.setPreviousStatement(true, null);
    this.setNextStatement(true, null);
    this.setColour(cs_arraylist_block_color);
    this.setTooltip("Remove");
    this.setHelpUrl("");
  },
};

csharpGenerator.forBlock["csharp_arraylist_remove"] = function (
  block,
  generator
) {
  // using
  generator.definitions_["import_system"] = "using System;";
  generator.definitions_["import_collections"] = "using System.Collections;";
  // Parameter
  var compA = generator.valueToCode(block, "compA", Order.NONE);
  var compB = generator.valueToCode(block, "compB", Order.NONE);
  // Code
  var codeText = `${compA}.Remove(${compB})`;

  return codeText;
};

///////////////////////////////////////////////////////////////////
// removeat
Blockly.Blocks["csharp_arraylist_removeat"] = {
  init: function () {
    this.appendValueInput("compA").setCheck(null);
    this.appendDummyInput().appendField(".RemoveAt(");
    this.appendValueInput("index").setCheck("Number");
    this.appendDummyInput().appendField(")");

    this.setInputsInline(true);
    this.setPreviousStatement(true, null);
    this.setNextStatement(true, null);
    this.setColour(cs_arraylist_block_color);
    this.setTooltip("RemoveAt");
    this.setHelpUrl("");
  },
};

csharpGenerator.forBlock["csharp_arraylist_removeat"] = function (
  block,
  generator
) {
  // using
  generator.definitions_["import_system"] = "using System;";
  generator.definitions_["import_collections"] = "using System.Collections;";
  // Parameter
  var compA = generator.valueToCode(block, "compA", Order.NONE);
  var compB = generator.valueToCode(block, "index", Order.NONE);
  // Code
  var codeText = `${compA}.RemoveAt(${compB})`;

  return codeText;
};

///////////////////////////////////////////////////////////////////
// reverse
Blockly.Blocks["csharp_arraylist_reverse"] = {
  init: function () {
    this.appendValueInput("compA").setCheck(null);
    this.appendDummyInput().appendField(".Reverse()");

    this.setInputsInline(true);
    this.setPreviousStatement(true, null);
    this.setNextStatement(true, null);
    this.setColour(cs_arraylist_block_color);
    this.setTooltip("Reverse");
    this.setHelpUrl("");
  },
};

csharpGenerator.forBlock["csharp_arraylist_reverse"] = function (
  block,
  generator
) {
  // using
  generator.definitions_["import_system"] = "using System;";
  generator.definitions_["import_collections"] = "using System.Collections;";
  // Parameter
  var compA = generator.valueToCode(block, "compA", Order.NONE);
  // Code
  var codeText = `${compA}.Reverse()`;

  return codeText;
};

///////////////////////////////////////////////////////////////////
// sort
Blockly.Blocks["csharp_arraylist_sort"] = {
  init: function () {
    this.appendValueInput("compA").setCheck(null);
    this.appendDummyInput().appendField(".Sort()");

    this.setInputsInline(true);
    this.setPreviousStatement(true, null);
    this.setNextStatement(true, null);
    this.setColour(cs_arraylist_block_color);
    this.setTooltip("Sort");
    this.setHelpUrl("");
  },
};

csharpGenerator.forBlock["csharp_arraylist_sort"] = function (
  block,
  generator
) {
  // using
  generator.definitions_["import_system"] = "using System;";
  generator.definitions_["import_collections"] = "using System.Collections;";
  // Parameter
  var compA = generator.valueToCode(block, "compA", Order.NONE);
  // Code
  var codeText = `${compA}.Sort()`;

  return codeText;
};

///////////////////////////////////////////////////////////////////
// tostring
Blockly.Blocks["csharp_arraylist_tostring"] = {
  init: function () {
    this.appendValueInput("compA").setCheck(null);
    this.appendDummyInput().appendField(".ToString()");

    this.setInputsInline(true);
    this.setPreviousStatement(false, null);
    this.setNextStatement(false, null);
    this.setOutput(true, "ToString");
    this.setColour(cs_arraylist_block_color);
    this.setTooltip("ToString");
    this.setHelpUrl("");
  },
};

csharpGenerator.forBlock["csharp_arraylist_tostring"] = function (
  block,
  generator
) {
  // using
  generator.definitions_["import_system"] = "using System;";
  generator.definitions_["import_collections"] = "using System.Collections;";
  // Parameter
  var compA = generator.valueToCode(block, "compA", Order.NONE);
  // Code
  var codeText = `${compA}.ToString()`;

  return [codeText, Order.NONE];
};
