import * as Blockly from "blockly/core";
import { csharpGenerator, Order } from "blockly/csharp";
import * as BlockColor from "./const.js";

// ブロックの配色
var cs_calc_block_color = BlockColor.cs_block_color;

// Timerブロック定義
const category_calcDef = `
    <category name="Math" colour="${cs_calc_block_color}">
    <block type="math_number">
      <field name="NUM">10</field>
    </block>
    <block type="csharp_calc_math_pi"></block>
    <block type="csharp_calc_math_e"></block>
    <block type="csharp_calc_operator_not"></block>
    <block type="csharp_calc_operator_0_value"></block>
    <block type="csharp_calc_operator_1_value"></block>
    <block type="csharp_calc_operator_2_value"></block>
    <block type="csharp_calc_operator_3_value"></block>
    <block type="csharp_calc_operator_4_value"></block>
    <block type="csharp_calc_operator_5_value"></block>
    <block type="csharp_calc_operator_6_value"></block>
    </category>`;

// <block type="math_single"></block>
// <block type="math_constant"></block>

export { category_calcDef };

///////////////////////////////////////////////////////////////////
// e
Blockly.Blocks["csharp_calc_math_pi"] = {
  init: function () {
    this.appendDummyInput().appendField("PI");
    this.setInputsInline(true);
    this.setPreviousStatement(false, null);
    this.setNextStatement(false, null);
    this.setOutput(true, null);
    this.setColour(cs_calc_block_color);
    this.setTooltip("PI");
    this.setHelpUrl("");
  },
};

csharpGenerator.forBlock["csharp_calc_math_pi"] = function (block, generator) {
  // using
  generator.definitions_["import_system"] = "using System;";
  // Parameter

  // Code
  var codeText = `Math.PI`;

  return [codeText, Order.NONE];
};

///////////////////////////////////////////////////////////////////
// e
Blockly.Blocks["csharp_calc_math_e"] = {
  init: function () {
    this.appendDummyInput().appendField("E");
    this.setInputsInline(true);
    this.setPreviousStatement(false, null);
    this.setNextStatement(false, null);
    this.setOutput(true, null);
    this.setColour(cs_calc_block_color);
    this.setTooltip("E");
    this.setHelpUrl("");
  },
};

csharpGenerator.forBlock["csharp_calc_math_e"] = function (block, generator) {
  // using
  generator.definitions_["import_system"] = "using System;";
  // Parameter

  // Code
  var codeText = `Math.E`;

  return [codeText, Order.NONE];
};

///////////////////////////////////////////////////////////////////
// operator
Blockly.Blocks["csharp_calc_operator_not"] = {
  init: function () {
    this.appendDummyInput().appendField(
      new Blockly.FieldDropdown([
        ["!", "!"],
        ["~", "~"],
      ]),
      "operator"
    );
    this.appendValueInput("varA").setCheck(null);

    this.setInputsInline(true);
    this.setPreviousStatement(false, null);
    this.setNextStatement(false, null);
    this.setOutput(true, null);
    this.setColour(cs_calc_block_color);
    this.setTooltip("operator");
    this.setHelpUrl("");
  },
};

csharpGenerator.forBlock["csharp_calc_operator_not"] = function (
  block,
  generator
) {
  // using
  generator.definitions_["import_system"] = "using System;";
  // Parameter
  var varA = generator.valueToCode(block, "varA", Order.NONE);
  var operator = block.getFieldValue("operator");
  // Code
  var codeText = `${operator}${varA}`;

  return [codeText, Order.NONE];
};

///////////////////////////////////////////////////////////////////
// operator
Blockly.Blocks["csharp_calc_operator_0_value"] = {
  init: function () {
    this.appendValueInput("varA").setCheck(null);
    this.appendDummyInput().appendField(
      new Blockly.FieldDropdown([
        ["=", "="],
        ["+", "+"],
        ["-", "-"],
        ["*", "*"],
        ["/", "/"],
        ["%", "%"],
      ]),
      "operator"
    );
    this.appendValueInput("varB").setCheck(null);

    this.setInputsInline(true);
    this.setPreviousStatement(false, null);
    this.setNextStatement(false, null);
    this.setOutput(true, null);
    this.setColour(cs_calc_block_color);
    this.setTooltip("operator");
    this.setHelpUrl("");
  },
};

csharpGenerator.forBlock["csharp_calc_operator_0_value"] = function (
  block,
  generator
) {
  // using
  generator.definitions_["import_system"] = "using System;";
  // Parameter
  var varA = generator.valueToCode(block, "varA", Order.NONE);
  var varB = generator.valueToCode(block, "varB", Order.NONE);
  var operator = block.getFieldValue("operator");
  // Code
  var codeText = `${varA} ${operator} ${varB}`;

  return [codeText, Order.NONE];
};

///////////////////////////////////////////////////////////////////
// operator
Blockly.Blocks["csharp_calc_operator_1_value"] = {
  init: function () {
    this.appendValueInput("varA").setCheck(null);
    this.appendDummyInput().appendField(
      new Blockly.FieldDropdown([
        ["&", "&"],
        ["|", "|"],
        ["^", "^"],
        ["&&", "&&"],
        ["||", "||"],
      ]),
      "operator"
    );
    this.appendValueInput("varB").setCheck(null);

    this.setInputsInline(true);
    this.setPreviousStatement(false, null);
    this.setNextStatement(false, null);
    this.setOutput(true, null);
    this.setColour(cs_calc_block_color);
    this.setTooltip("operator");
    this.setHelpUrl("");
  },
};

csharpGenerator.forBlock["csharp_calc_operator_1_value"] = function (
  block,
  generator
) {
  // using
  generator.definitions_["import_system"] = "using System;";
  // Parameter
  var varA = generator.valueToCode(block, "varA", Order.NONE);
  var varB = generator.valueToCode(block, "varB", Order.NONE);
  var operator = block.getFieldValue("operator");
  // Code
  var codeText = `${varA} ${operator} ${varB}`;

  return [codeText, Order.NONE];
};

///////////////////////////////////////////////////////////////////
// operator
Blockly.Blocks["csharp_calc_operator_2_value"] = {
  init: function () {
    this.appendValueInput("varA").setCheck(null);
    this.appendDummyInput().appendField(
      new Blockly.FieldDropdown([
        ["+=", "+="],
        ["-=", "-="],
        ["*=", "*="],
        ["/=", "/="],
        ["&=", "&="],
        ["|=", "|="],
        ["^=", "^="],
        [">=", ">="],
        ["<=", "<="],
        [">>=", ">>="],
        ["<<=", "<<="],
        // [">>>=", ">>>="],
        // ["<<<=", "<<<="],
      ]),
      "operator"
    );
    this.appendValueInput("varB").setCheck(null);

    this.setInputsInline(true);
    this.setPreviousStatement(false, null);
    this.setNextStatement(false, null);
    this.setOutput(true, null);
    this.setColour(cs_calc_block_color);
    this.setTooltip("operator");
    this.setHelpUrl("");
  },
};

csharpGenerator.forBlock["csharp_calc_operator_2_value"] = function (
  block,
  generator
) {
  // using
  generator.definitions_["import_system"] = "using System;";
  // Parameter
  var varA = generator.valueToCode(block, "varA", Order.NONE);
  var varB = generator.valueToCode(block, "varB", Order.NONE);
  var operator = block.getFieldValue("operator");
  // Code
  var codeText = `${varA} ${operator} ${varB} `;

  return [codeText, Order.NONE];
};

///////////////////////////////////////////////////////////////////
// operator
Blockly.Blocks["csharp_calc_operator_3_value"] = {
  init: function () {
    this.appendValueInput("varA").setCheck(null);
    this.appendDummyInput().appendField(
      new Blockly.FieldDropdown([
        [">>", ">>"],
        ["<<", "<<"],
        // [">>>", ">>>"],
        // ["<<<", "<<<"],
      ]),
      "operator"
    );
    this.appendValueInput("varB").setCheck(null);

    this.setInputsInline(true);
    this.setPreviousStatement(false, null);
    this.setNextStatement(false, null);
    this.setOutput(true, null);
    this.setColour(cs_calc_block_color);
    this.setTooltip("operator");
    this.setHelpUrl("");
  },
};

csharpGenerator.forBlock["csharp_calc_operator_3_value"] = function (
  block,
  generator
) {
  // using
  generator.definitions_["import_system"] = "using System;";
  // Parameter
  var varA = generator.valueToCode(block, "varA", Order.NONE);
  var varB = generator.valueToCode(block, "varB", Order.NONE);
  var operator = block.getFieldValue("operator");
  // Code
  var codeText = `${varA} ${operator} ${varB}`;

  return [codeText, Order.NONE];
};

///////////////////////////////////////////////////////////////////
// operator
Blockly.Blocks["csharp_calc_operator_4_value"] = {
  init: function () {
    this.appendDummyInput().appendField(
      new Blockly.FieldDropdown([
        ["Abs", "Math.Abs"],
        ["Ceiling", "Math.Ceiling"],
        ["Floor", "Math.Floor"],
        ["Exp", "Math.Exp"],
        ["Round", "Math.Round"],
        ["Truncate", "Math.Truncate"],
        ["Log", "Math.Log"],
        ["Log2", "Math.Log2"],
        ["Sqrt", "Math.Sqrt"],
        ["Pow", "Math.Pow"],
      ]),
      "operator"
    );
    this.appendValueInput("varA").setCheck(null);

    this.setInputsInline(true);
    this.setPreviousStatement(false, null);
    this.setNextStatement(false, null);
    this.setOutput(true, null);
    this.setColour(cs_calc_block_color);
    this.setTooltip("operator");
    this.setHelpUrl("");
  },
};

csharpGenerator.forBlock["csharp_calc_operator_4_value"] = function (
  block,
  generator
) {
  // using
  generator.definitions_["import_system"] = "using System;";
  // Parameter
  var varA = generator.valueToCode(block, "varA", Order.NONE);
  var operator = block.getFieldValue("operator");
  // Code
  var codeText = `${operator}(${varA})`;

  return [codeText, Order.NONE];
};

///////////////////////////////////////////////////////////////////
// operator
Blockly.Blocks["csharp_calc_operator_5_value"] = {
  init: function () {
    this.appendDummyInput().appendField(
      new Blockly.FieldDropdown([
        ["Sin", "Math.Sin"],
        ["Cos", "Math.Cos"],
        ["Tan", "Math.Tan"],
        ["Asin", "Math.ASin"],
        ["Acos", "Math.ACos"],
        ["Atan", "Math.Atan"],
      ]),
      "operator"
    );
    this.appendValueInput("varA").setCheck(null);

    this.setInputsInline(true);
    this.setPreviousStatement(false, null);
    this.setNextStatement(false, null);
    this.setOutput(true, null);
    this.setColour(cs_calc_block_color);
    this.setTooltip("operator");
    this.setHelpUrl("");
  },
};

csharpGenerator.forBlock["csharp_calc_operator_5_value"] = function (
  block,
  generator
) {
  // using
  generator.definitions_["import_system"] = "using System;";
  // Parameter
  var varA = generator.valueToCode(block, "varA", Order.NONE);
  var operator = block.getFieldValue("operator");
  // Code
  var codeText = `${operator}(${varA})`;

  return [codeText, Order.NONE];
};

///////////////////////////////////////////////////////////////////
// operator
Blockly.Blocks["csharp_calc_operator_6_value"] = {
  init: function () {
    this.appendDummyInput().appendField(
      new Blockly.FieldDropdown([
        ["Min", "Math.Min"],
        ["Max", "Math.Max"],
      ]),
      "operator"
    );
    this.appendDummyInput().appendField("(");
    this.appendValueInput("varA").setCheck(null);
    this.appendDummyInput().appendField(",");
    this.appendValueInput("varB").setCheck(null);
    this.appendDummyInput().appendField(")");

    this.setInputsInline(true);
    this.setPreviousStatement(false, null);
    this.setNextStatement(false, null);
    this.setOutput(true, null);
    this.setColour(cs_calc_block_color);
    this.setTooltip("operator");
    this.setHelpUrl("");
  },
};

csharpGenerator.forBlock["csharp_calc_operator_6_value"] = function (
  block,
  generator
) {
  // using
  generator.definitions_["import_system"] = "using System;";
  // Parameter
  var varA = generator.valueToCode(block, "varA", Order.NONE);
  var varB = generator.valueToCode(block, "varB", Order.NONE);
  var operator = block.getFieldValue("operator");
  // Code
  var codeText = `${operator}(${varA}, ${varB})`;

  return [codeText, Order.NONE];
};
