import * as Blockly from "blockly/core";
import { csharpGenerator, Order } from "blockly/csharp";
import * as BlockColor from "./const.js";

// ブロックの配色
var cs_string_block_color = BlockColor.cs_block_color;

// Timerブロック定義
const category_stringDef = `
    <category name="String" colour="${cs_string_block_color}">
    <block type="text">
    </block>
    <block type="csharp_string_length"></block>
    <block type="csharp_string_compare"></block>
    <block type="csharp_string_concat"></block>
    <block type="csharp_string_contains"></block>
    <block type="csharp_string_endswith"></block>
    <block type="csharp_string_indexof"></block>
    <block type="csharp_string_insert">
      <value name="index">
        <block type="math_number">
          <field name="NUM">0</field>
        </block>
      </value>
    </block>
    <block type="csharp_string_lastindexof"></block>
    <block type="csharp_string_startswith"></block>
    <block type="csharp_string_substring">
      <value name="startindex">
        <block type="math_number">
          <field name="NUM">0</field>
        </block>
      </value>
      <value name="length">
        <block type="math_number">
          <field name="NUM">1</field>
        </block>
      </value>
    </block>
    <block type="csharp_string_trim"></block>
    </category>`;

// <block type="csharp_string_text">
//   <value name="text">
//     <block type="text">
//         <field name="TEXT">text</field>
//     </block>
//   </value>
// </block>

// <block type="csharp_calc_operator_not"></block>
// <block type="csharp_calc_operator_0_value"></block>
// <block type="csharp_calc_operator_1_value"></block>
// <block type="csharp_calc_operator_2_value"></block>
// <block type="csharp_calc_operator_3_value"></block>
// <block type="csharp_calc_operator_4_value"></block>
// <block type="csharp_calc_operator_5_value"></block>
// <block type="csharp_calc_operator_6_value"></block>
// <block type="math_single"></block>
// <block type="math_constant"></block>

export { category_stringDef };

///////////////////////////////////////////////////////////////////
// length
Blockly.Blocks["csharp_string_length"] = {
  init: function () {
    this.appendValueInput("compA").setCheck(null);
    this.appendDummyInput().appendField(".Length");

    this.setInputsInline(true);
    this.setPreviousStatement(false, null);
    this.setNextStatement(false, null);
    this.setOutput(true, "Length");
    this.setColour(cs_string_block_color);
    this.setTooltip("length");
    this.setHelpUrl("");
  },
};

csharpGenerator.forBlock["csharp_string_length"] = function (block, generator) {
  // using
  generator.definitions_["import_system"] = "using System;";
  // Parameter
  var compA = generator.valueToCode(block, "compA", Order.NONE);
  // Code
  var codeText = `${compA}.Length`;

  return [codeText, Order.NONE];
};

///////////////////////////////////////////////////////////////////
// compare
Blockly.Blocks["csharp_string_compare"] = {
  init: function () {
    this.appendDummyInput().appendField("String.Compare(");
    this.appendValueInput("compA").setCheck(null);
    this.appendDummyInput().appendField(",");
    this.appendValueInput("compB").setCheck(null);
    this.appendDummyInput().appendField(")");

    this.setInputsInline(true);
    this.setPreviousStatement(false, null);
    this.setNextStatement(false, null);
    this.setOutput(true, "Compare");
    this.setColour(cs_string_block_color);
    this.setTooltip("Compare");
    this.setHelpUrl("");
  },
};

csharpGenerator.forBlock["csharp_string_compare"] = function (
  block,
  generator
) {
  // using
  generator.definitions_["import_system"] = "using System;";
  // Parameter
  var compA = generator.valueToCode(block, "compA", Order.NONE);
  var compB = generator.valueToCode(block, "compB", Order.NONE);
  // Code
  var codeText = `String.Compare(${compA},${compB})`;

  return [codeText, Order.NONE];
};

///////////////////////////////////////////////////////////////////
// concat
Blockly.Blocks["csharp_string_concat"] = {
  init: function () {
    this.appendDummyInput().appendField("String.Concat(");
    this.appendValueInput("compA").setCheck(null);
    this.appendDummyInput().appendField(",");
    this.appendValueInput("compB").setCheck(null);
    this.appendDummyInput().appendField(")");

    this.setInputsInline(true);
    this.setPreviousStatement(false, null);
    this.setNextStatement(false, null);
    this.setOutput(true, "Concat");
    this.setColour(cs_string_block_color);
    this.setTooltip("Concat");
    this.setHelpUrl("");
  },
};

csharpGenerator.forBlock["csharp_string_concat"] = function (block, generator) {
  // using
  generator.definitions_["import_system"] = "using System;";
  // Parameter
  var compA = generator.valueToCode(block, "compA", Order.NONE);
  var compB = generator.valueToCode(block, "compB", Order.NONE);
  // Code
  var codeText = `String.Concat(${compA},${compB})`;

  return [codeText, Order.NONE];
};

///////////////////////////////////////////////////////////////////
// contains
Blockly.Blocks["csharp_string_contains"] = {
  init: function () {
    this.appendValueInput("compA").setCheck(null);
    this.appendDummyInput().appendField(".Contains(");
    this.appendValueInput("compB").setCheck(null);
    this.appendDummyInput().appendField(")");

    this.setInputsInline(true);
    this.setPreviousStatement(false, null);
    this.setNextStatement(false, null);
    this.setOutput(true, "Contains");
    this.setColour(cs_string_block_color);
    this.setTooltip("Contains");
    this.setHelpUrl("");
  },
};

csharpGenerator.forBlock["csharp_string_contains"] = function (
  block,
  generator
) {
  // using
  generator.definitions_["import_system"] = "using System;";
  // Parameter
  var compA = generator.valueToCode(block, "compA", Order.NONE);
  var compB = generator.valueToCode(block, "compB", Order.NONE);
  // Code
  var codeText = `${compA}.Contains(${compB})`;

  return [codeText, Order.NONE];
};

///////////////////////////////////////////////////////////////////
// endswith
Blockly.Blocks["csharp_string_endswith"] = {
  init: function () {
    this.appendValueInput("compA").setCheck(null);
    this.appendDummyInput().appendField(".EndsWith(");
    this.appendValueInput("compB").setCheck(null);
    this.appendDummyInput().appendField(")");

    this.setInputsInline(true);
    this.setPreviousStatement(false, null);
    this.setNextStatement(false, null);
    this.setOutput(true, "EndsWith");
    this.setColour(cs_string_block_color);
    this.setTooltip("EndsWith");
    this.setHelpUrl("");
  },
};

csharpGenerator.forBlock["csharp_string_endswith"] = function (
  block,
  generator
) {
  // using
  generator.definitions_["import_system"] = "using System;";
  // Parameter
  var compA = generator.valueToCode(block, "compA", Order.NONE);
  var compB = generator.valueToCode(block, "compB", Order.NONE);
  // Code
  var codeText = `${compA}.EndsWith(${compB})`;

  return [codeText, Order.NONE];
};

///////////////////////////////////////////////////////////////////
// indexof
Blockly.Blocks["csharp_string_indexof"] = {
  init: function () {
    this.appendValueInput("compA").setCheck(null);
    this.appendDummyInput().appendField(".IndexOf(");
    this.appendValueInput("compB").setCheck(null);
    this.appendDummyInput().appendField(")");

    this.setInputsInline(true);
    this.setPreviousStatement(false, null);
    this.setNextStatement(false, null);
    this.setOutput(true, "IndexOf");
    this.setColour(cs_string_block_color);
    this.setTooltip("IndexOf");
    this.setHelpUrl("");
  },
};

csharpGenerator.forBlock["csharp_string_indexof"] = function (
  block,
  generator
) {
  // using
  generator.definitions_["import_system"] = "using System;";
  // Parameter
  var compA = generator.valueToCode(block, "compA", Order.NONE);
  var compB = generator.valueToCode(block, "compB", Order.NONE);
  // Code
  var codeText = `${compA}.IndexOf(${compB})`;

  return [codeText, Order.NONE];
};

///////////////////////////////////////////////////////////////////
// insert
Blockly.Blocks["csharp_string_insert"] = {
  init: function () {
    this.appendValueInput("compA").setCheck(null);
    this.appendDummyInput().appendField(".Insert(");
    this.appendValueInput("index").setCheck("Number");
    this.appendDummyInput().appendField(",");
    this.appendValueInput("compB").setCheck(null);
    this.appendDummyInput().appendField(")");

    this.setInputsInline(true);
    this.setPreviousStatement(false, null);
    this.setNextStatement(false, null);
    this.setOutput(true, "Insert");
    this.setColour(cs_string_block_color);
    this.setTooltip("Insert");
    this.setHelpUrl("");
  },
};

csharpGenerator.forBlock["csharp_string_insert"] = function (block, generator) {
  // using
  generator.definitions_["import_system"] = "using System;";
  // Parameter
  var compA = generator.valueToCode(block, "compA", Order.NONE);
  var compB = generator.valueToCode(block, "index", Order.NONE);
  var compC = generator.valueToCode(block, "compB", Order.NONE);
  // Code
  var codeText = `${compA}.Insert(${compB},${compC})`;

  return [codeText, Order.NONE];
};

///////////////////////////////////////////////////////////////////
// lastindexof
Blockly.Blocks["csharp_string_lastindexof"] = {
  init: function () {
    this.appendValueInput("compA").setCheck(null);
    this.appendDummyInput().appendField(".LastIndexOf(");
    this.appendValueInput("compB").setCheck(null);
    this.appendDummyInput().appendField(")");

    this.setInputsInline(true);
    this.setPreviousStatement(false, null);
    this.setNextStatement(false, null);
    this.setOutput(true, "LastIndexOf");
    this.setColour(cs_string_block_color);
    this.setTooltip("LastIndexOf");
    this.setHelpUrl("");
  },
};

csharpGenerator.forBlock["csharp_string_lastindexof"] = function (
  block,
  generator
) {
  // using
  generator.definitions_["import_system"] = "using System;";
  // Parameter
  var compA = generator.valueToCode(block, "compA", Order.NONE);
  var compB = generator.valueToCode(block, "compB", Order.NONE);
  // Code
  var codeText = `${compA}.LastIndexOf(${compB})`;

  return [codeText, Order.NONE];
};

///////////////////////////////////////////////////////////////////
// startswith
Blockly.Blocks["csharp_string_startswith"] = {
  init: function () {
    this.appendValueInput("compA").setCheck(null);
    this.appendDummyInput().appendField(".StartsWith(");
    this.appendValueInput("compB").setCheck(null);
    this.appendDummyInput().appendField(")");

    this.setInputsInline(true);
    this.setPreviousStatement(false, null);
    this.setNextStatement(false, null);
    this.setOutput(true, "StartsWith");
    this.setColour(cs_string_block_color);
    this.setTooltip("StartsWith");
    this.setHelpUrl("");
  },
};

csharpGenerator.forBlock["csharp_string_startswith"] = function (
  block,
  generator
) {
  // using
  generator.definitions_["import_system"] = "using System;";
  // Parameter
  var compA = generator.valueToCode(block, "compA", Order.NONE);
  var compB = generator.valueToCode(block, "compB", Order.NONE);
  // Code
  var codeText = `${compA}.StartsWith(${compB})`;

  return [codeText, Order.NONE];
};

///////////////////////////////////////////////////////////////////
// substring
Blockly.Blocks["csharp_string_substring"] = {
  init: function () {
    this.appendValueInput("compA").setCheck(null);
    this.appendDummyInput().appendField(".Substring(");
    this.appendValueInput("startindex").setCheck("Number");
    this.appendDummyInput().appendField(",");
    this.appendValueInput("length").setCheck("Number");
    this.appendDummyInput().appendField(")");

    this.setInputsInline(true);
    this.setPreviousStatement(false, null);
    this.setNextStatement(false, null);
    this.setOutput(true, "StartsWith");
    this.setColour(cs_string_block_color);
    this.setTooltip("StartsWith");
    this.setHelpUrl("");
  },
};

csharpGenerator.forBlock["csharp_string_substring"] = function (
  block,
  generator
) {
  // using
  generator.definitions_["import_system"] = "using System;";
  // Parameter
  var compA = generator.valueToCode(block, "compA", Order.NONE);
  var compB = generator.valueToCode(block, "startindex", Order.NONE);
  var compC = generator.valueToCode(block, "length", Order.NONE);
  // Code
  var codeText = `${compA}.Substring(${compB},${compC})`;

  return [codeText, Order.NONE];
};

///////////////////////////////////////////////////////////////////
// trim
Blockly.Blocks["csharp_string_trim"] = {
  init: function () {
    this.appendValueInput("compA").setCheck(null);
    this.appendDummyInput().appendField(".Trim()");

    this.setInputsInline(true);
    this.setPreviousStatement(false, null);
    this.setNextStatement(false, null);
    this.setOutput(true, "Trim");
    this.setColour(cs_string_block_color);
    this.setTooltip("Trim");
    this.setHelpUrl("");
  },
};

csharpGenerator.forBlock["csharp_string_trim"] = function (block, generator) {
  // using
  generator.definitions_["import_system"] = "using System;";
  // Parameter
  var compA = generator.valueToCode(block, "compA", Order.NONE);
  // Code
  var codeText = `${compA}.Trim()`;

  return [codeText, Order.NONE];
};

// ///////////////////////////////////////////////////////////////////
// // e
// Blockly.Blocks["csharp_string_string"] = {
//   init: function () {
//     this.appendDummyInput().appendField("PI");
//     this.setInputsInline(true);
//     this.setPreviousStatement(false, null);
//     this.setNextStatement(false, null);
//     this.setOutput(true, null);
//     this.setColour(cs_calc_block_color);
//     this.setTooltip("PI");
//     this.setHelpUrl("");
//   },
// };

// csharpGenerator.forBlock["csharp_calc_math_pi"] = function (block, generator) {
//   // using
//   generator.definitions_["import_system"] = "using System;";
//   // Parameter

//   // Code
//   var codeText = `Math.PI`;

//   return [codeText, Order.NONE];
// };

// ///////////////////////////////////////////////////////////////////
// // e
// Blockly.Blocks["csharp_calc_math_e"] = {
//   init: function () {
//     this.appendDummyInput().appendField("E");
//     this.setInputsInline(true);
//     this.setPreviousStatement(false, null);
//     this.setNextStatement(false, null);
//     this.setOutput(true, null);
//     this.setColour(cs_calc_block_color);
//     this.setTooltip("E");
//     this.setHelpUrl("");
//   },
// };

// csharpGenerator.forBlock["csharp_calc_math_e"] = function (block, generator) {
//   // using
//   generator.definitions_["import_system"] = "using System;";
//   // Parameter

//   // Code
//   var codeText = `Math.E`;

//   return [codeText, Order.NONE];
// };

// ///////////////////////////////////////////////////////////////////
// // operator
// Blockly.Blocks["csharp_calc_operator_not"] = {
//   init: function () {
//     this.appendDummyInput().appendField(
//       new Blockly.FieldDropdown([
//         ["!", "!"],
//         ["~", "~"],
//       ]),
//       "operator"
//     );
//     this.appendValueInput("varA").setCheck(null);

//     this.setInputsInline(true);
//     this.setPreviousStatement(false, null);
//     this.setNextStatement(false, null);
//     this.setOutput(true, null);
//     this.setColour(cs_calc_block_color);
//     this.setTooltip("operator");
//     this.setHelpUrl("");
//   },
// };

// csharpGenerator.forBlock["csharp_calc_operator_not"] = function (
//   block,
//   generator
// ) {
//   // using
//   generator.definitions_["import_system"] = "using System;";
//   // Parameter
//   var varA = generator.valueToCode(block, "varA", Order.NONE);
//   var operator = block.getFieldValue("operator");
//   // Code
//   var codeText = `${operator}${varA}`;

//   return [codeText, Order.NONE];
// };

// ///////////////////////////////////////////////////////////////////
// // operator
// Blockly.Blocks["csharp_calc_operator_0_value"] = {
//   init: function () {
//     this.appendValueInput("varA").setCheck(null);
//     this.appendDummyInput().appendField(
//       new Blockly.FieldDropdown([
//         ["=", "="],
//         ["+", "+"],
//         ["-", "-"],
//         ["*", "*"],
//         ["/", "/"],
//         ["%", "%"],
//       ]),
//       "operator"
//     );
//     this.appendValueInput("varB").setCheck(null);

//     this.setInputsInline(true);
//     this.setPreviousStatement(false, null);
//     this.setNextStatement(false, null);
//     this.setOutput(true, null);
//     this.setColour(cs_calc_block_color);
//     this.setTooltip("operator");
//     this.setHelpUrl("");
//   },
// };

// csharpGenerator.forBlock["csharp_calc_operator_0_value"] = function (
//   block,
//   generator
// ) {
//   // using
//   generator.definitions_["import_system"] = "using System;";
//   // Parameter
//   var varA = generator.valueToCode(block, "varA", Order.NONE);
//   var varB = generator.valueToCode(block, "varB", Order.NONE);
//   var operator = block.getFieldValue("operator");
//   // Code
//   var codeText = `${varA} ${operator} ${varB}`;

//   return [codeText, Order.NONE];
// };

// ///////////////////////////////////////////////////////////////////
// // operator
// Blockly.Blocks["csharp_calc_operator_1_value"] = {
//   init: function () {
//     this.appendValueInput("varA").setCheck(null);
//     this.appendDummyInput().appendField(
//       new Blockly.FieldDropdown([
//         ["&", "&"],
//         ["|", "|"],
//         ["^", "^"],
//         ["&&", "&&"],
//         ["||", "||"],
//       ]),
//       "operator"
//     );
//     this.appendValueInput("varB").setCheck(null);

//     this.setInputsInline(true);
//     this.setPreviousStatement(false, null);
//     this.setNextStatement(false, null);
//     this.setOutput(true, null);
//     this.setColour(cs_calc_block_color);
//     this.setTooltip("operator");
//     this.setHelpUrl("");
//   },
// };

// csharpGenerator.forBlock["csharp_calc_operator_1_value"] = function (
//   block,
//   generator
// ) {
//   // using
//   generator.definitions_["import_system"] = "using System;";
//   // Parameter
//   var varA = generator.valueToCode(block, "varA", Order.NONE);
//   var varB = generator.valueToCode(block, "varB", Order.NONE);
//   var operator = block.getFieldValue("operator");
//   // Code
//   var codeText = `${varA} ${operator} ${varB}`;

//   return [codeText, Order.NONE];
// };

// ///////////////////////////////////////////////////////////////////
// // operator
// Blockly.Blocks["csharp_calc_operator_2_value"] = {
//   init: function () {
//     this.appendValueInput("varA").setCheck(null);
//     this.appendDummyInput().appendField(
//       new Blockly.FieldDropdown([
//         ["+=", "+="],
//         ["-=", "-="],
//         ["*=", "*="],
//         ["/=", "/="],
//         ["&=", "&="],
//         ["|=", "|="],
//         ["^=", "^="],
//         [">=", ">="],
//         ["<=", "<="],
//         [">>=", ">>="],
//         ["<<=", "<<="],
//         // [">>>=", ">>>="],
//         // ["<<<=", "<<<="],
//       ]),
//       "operator"
//     );
//     this.appendValueInput("varB").setCheck(null);

//     this.setInputsInline(true);
//     this.setPreviousStatement(false, null);
//     this.setNextStatement(false, null);
//     this.setOutput(true, null);
//     this.setColour(cs_calc_block_color);
//     this.setTooltip("operator");
//     this.setHelpUrl("");
//   },
// };

// csharpGenerator.forBlock["csharp_calc_operator_2_value"] = function (
//   block,
//   generator
// ) {
//   // using
//   generator.definitions_["import_system"] = "using System;";
//   // Parameter
//   var varA = generator.valueToCode(block, "varA", Order.NONE);
//   var varB = generator.valueToCode(block, "varB", Order.NONE);
//   var operator = block.getFieldValue("operator");
//   // Code
//   var codeText = `${varA} ${operator} ${varB} `;

//   return [codeText, Order.NONE];
// };

// ///////////////////////////////////////////////////////////////////
// // operator
// Blockly.Blocks["csharp_calc_operator_3_value"] = {
//   init: function () {
//     this.appendValueInput("varA").setCheck(null);
//     this.appendDummyInput().appendField(
//       new Blockly.FieldDropdown([
//         [">>", ">>"],
//         ["<<", "<<"],
//         // [">>>", ">>>"],
//         // ["<<<", "<<<"],
//       ]),
//       "operator"
//     );
//     this.appendValueInput("varB").setCheck(null);

//     this.setInputsInline(true);
//     this.setPreviousStatement(false, null);
//     this.setNextStatement(false, null);
//     this.setOutput(true, null);
//     this.setColour(cs_calc_block_color);
//     this.setTooltip("operator");
//     this.setHelpUrl("");
//   },
// };

// csharpGenerator.forBlock["csharp_calc_operator_3_value"] = function (
//   block,
//   generator
// ) {
//   // using
//   generator.definitions_["import_system"] = "using System;";
//   // Parameter
//   var varA = generator.valueToCode(block, "varA", Order.NONE);
//   var varB = generator.valueToCode(block, "varB", Order.NONE);
//   var operator = block.getFieldValue("operator");
//   // Code
//   var codeText = `${varA} ${operator} ${varB}`;

//   return [codeText, Order.NONE];
// };

// ///////////////////////////////////////////////////////////////////
// // operator
// Blockly.Blocks["csharp_calc_operator_4_value"] = {
//   init: function () {
//     this.appendDummyInput().appendField(
//       new Blockly.FieldDropdown([
//         ["Abs", "Math.Abs"],
//         ["Ceiling", "Math.Ceiling"],
//         ["Floor", "Math.Floor"],
//         ["Exp", "Math.Exp"],
//         ["Round", "Math.Round"],
//         ["Truncate", "Math.Truncate"],
//         ["Log", "Math.Log"],
//         ["Log2", "Math.Log2"],
//         ["Sqrt", "Math.Sqrt"],
//         ["Pow", "Math.Pow"],
//       ]),
//       "operator"
//     );
//     this.appendValueInput("varA").setCheck(null);

//     this.setInputsInline(true);
//     this.setPreviousStatement(false, null);
//     this.setNextStatement(false, null);
//     this.setOutput(true, null);
//     this.setColour(cs_calc_block_color);
//     this.setTooltip("operator");
//     this.setHelpUrl("");
//   },
// };

// csharpGenerator.forBlock["csharp_calc_operator_4_value"] = function (
//   block,
//   generator
// ) {
//   // using
//   generator.definitions_["import_system"] = "using System;";
//   // Parameter
//   var varA = generator.valueToCode(block, "varA", Order.NONE);
//   var operator = block.getFieldValue("operator");
//   // Code
//   var codeText = `${operator}(${varA})`;

//   return [codeText, Order.NONE];
// };

// ///////////////////////////////////////////////////////////////////
// // operator
// Blockly.Blocks["csharp_calc_operator_5_value"] = {
//   init: function () {
//     this.appendDummyInput().appendField(
//       new Blockly.FieldDropdown([
//         ["Sin", "Math.Sin"],
//         ["Cos", "Math.Cos"],
//         ["Tan", "Math.Tan"],
//         ["Asin", "Math.ASin"],
//         ["Acos", "Math.ACos"],
//         ["Atan", "Math.Atan"],
//       ]),
//       "operator"
//     );
//     this.appendValueInput("varA").setCheck(null);

//     this.setInputsInline(true);
//     this.setPreviousStatement(false, null);
//     this.setNextStatement(false, null);
//     this.setOutput(true, null);
//     this.setColour(cs_calc_block_color);
//     this.setTooltip("operator");
//     this.setHelpUrl("");
//   },
// };

// csharpGenerator.forBlock["csharp_calc_operator_5_value"] = function (
//   block,
//   generator
// ) {
//   // using
//   generator.definitions_["import_system"] = "using System;";
//   // Parameter
//   var varA = generator.valueToCode(block, "varA", Order.NONE);
//   var operator = block.getFieldValue("operator");
//   // Code
//   var codeText = `${operator}(${varA})`;

//   return [codeText, Order.NONE];
// };

// ///////////////////////////////////////////////////////////////////
// // operator
// Blockly.Blocks["csharp_calc_operator_6_value"] = {
//   init: function () {
//     this.appendDummyInput().appendField(
//       new Blockly.FieldDropdown([
//         ["Min", "Math.Min"],
//         ["Max", "Math.Max"],
//       ]),
//       "operator"
//     );
//     this.appendDummyInput().appendField("(");
//     this.appendValueInput("varA").setCheck(null);
//     this.appendDummyInput().appendField(",");
//     this.appendValueInput("varB").setCheck(null);
//     this.appendDummyInput().appendField(")");

//     this.setInputsInline(true);
//     this.setPreviousStatement(false, null);
//     this.setNextStatement(false, null);
//     this.setOutput(true, null);
//     this.setColour(cs_calc_block_color);
//     this.setTooltip("operator");
//     this.setHelpUrl("");
//   },
// };

// csharpGenerator.forBlock["csharp_calc_operator_6_value"] = function (
//   block,
//   generator
// ) {
//   // using
//   generator.definitions_["import_system"] = "using System;";
//   // Parameter
//   var varA = generator.valueToCode(block, "varA", Order.NONE);
//   var varB = generator.valueToCode(block, "varB", Order.NONE);
//   var operator = block.getFieldValue("operator");
//   // Code
//   var codeText = `${operator}(${varA}, ${varB})`;

//   return [codeText, Order.NONE];
// };
