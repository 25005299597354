<template>
  <v-container class="fill-height">
    <v-responsive class="align-center text-center fill-height">
      <v-row class="d-flex align-center justify-center">
        <v-col cols="auto">
          <div class="header">Broccoly Coder</div>
          <div class="logo">C# Blocks</div>
        </v-col>
      </v-row>

      <v-row class="d-flex align-center justify-center">
        <v-col cols="auto">
          <v-btn
            min-width="164"
            rel="noopener noreferrer"
            text
            Elevation="0"
            @click="toMain"
          >
            <v-icon icon="mdi-view-dashboard" size="large" start />
            start
          </v-btn>
        </v-col>

        <!-- <v-col cols="auto">
          <v-btn
            to="/main"
            min-width="164"
            rel="noopener noreferrer"
            text
            Elevation="0"
          >
            <v-icon icon="mdi-account-group" size="large" start />
            about
          </v-btn>
        </v-col> -->
      </v-row>
    </v-responsive>
  </v-container>
</template>

<script>
export default {
  name: "TitleItem",

  methods: {
    // メイン画面に遷移
    toMain() {
      this.$emit("titleProc", "move to main");
    },
  },
};
</script>

<style scoped>
@import url("https://fonts.googleapis.com/css2?family=Handlee&display=swap");

html,
body {
  min-width: 1080px;
  background-color: #eee;
}

div.header {
  line-height: 1.5;
  max-height: 100vh;
  text-align: left;
}

div.logo {
  display: block;
  width: 100%;
  font-size: 64px;
  text-align: center;
  font-family: "Handlee", cursive;
}

@media (min-width: 1024px) {
  html,
  body {
    min-width: 1080px;
    background-color: #eee;
  }

  div.header {
    margin: 0 0 0 0;
    display: block;
    width: 100%;
    font-size: 24px;
    text-align: left;
  }

  div.logo {
    margin: -1rem 0 0 0;
    display: block;
    width: 100%;
    text-align: center;
    font-size: 160px;
    font-family: "Handlee", cursive;
  }
}
</style>
