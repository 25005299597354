import * as Blockly from "blockly/core";
import { csharpGenerator, Order } from "blockly/csharp";
import * as BlockColor from "./const.js";

// ブロックの配色
var cs_date_block_color = BlockColor.cs_block_color;

// Timerブロック定義
const category_dateDef = `
    <category name="Date" colour="${cs_date_block_color}">
    <block type="csharp_date_new">
      <value name="year">
        <block type="math_number">
          <field name="NUM">2000</field>
        </block>
      </value>
      <value name="month">
        <block type="math_number">
          <field name="NUM">1</field>
        </block>
      </value>
      <value name="day">
        <block type="math_number">
          <field name="NUM">1</field>
        </block>
      </value>
    </block>
    <block type="csharp_date_day"></block>
    <block type="csharp_date_daynumber"></block>
    <block type="csharp_date_dayofweek"></block>
    <block type="csharp_date_dayofyear"></block>
    <block type="csharp_date_month"></block>
    <block type="csharp_date_year"></block>
    <block type="csharp_date_adddays">
      <value name="day">
        <block type="math_number">
          <field name="NUM">1</field>
        </block>
      </value>
    </block>
    <block type="csharp_date_addmonths">
      <value name="month">
        <block type="math_number">
          <field name="NUM">1</field>
        </block>
      </value>
    </block>
    <block type="csharp_date_addyears">
      <value name="year">
        <block type="math_number">
          <field name="NUM">1</field>
        </block>
      </value>
    </block>
    <block type="csharp_date_tolongdatestring"></block>
    <block type="csharp_date_toshortdatestring"></block>
    <block type="csharp_date_tostring"></block>

    </category>`;

export { category_dateDef };

///////////////////////////////////////////////////////////////////
// new
Blockly.Blocks["csharp_date_new"] = {
  init: function () {
    this.appendDummyInput().appendField("new DateOnly(");
    this.appendValueInput("year").setCheck("Number");
    this.appendDummyInput().appendField(",");
    this.appendValueInput("month").setCheck("Number");
    this.appendDummyInput().appendField(",");
    this.appendValueInput("day").setCheck("Number");
    this.appendDummyInput().appendField(")");

    this.setInputsInline(true);
    this.setPreviousStatement(false, null);
    this.setNextStatement(false, null);
    this.setOutput(true, "DateOnly");
    this.setColour(cs_date_block_color);
    this.setTooltip("DateOnly");
    this.setHelpUrl("");
  },
};

csharpGenerator.forBlock["csharp_date_new"] = function (block, generator) {
  // using
  generator.definitions_["import_system"] = "using System;";
  // Parameter
  var compA = generator.valueToCode(block, "year", Order.NONE);
  var compB = generator.valueToCode(block, "month", Order.NONE);
  var compC = generator.valueToCode(block, "day", Order.NONE);
  // Code
  var codeText = `new DateOnly(${compA},${compB},${compC})`;

  return [codeText, Order.NONE];
};

///////////////////////////////////////////////////////////////////
// day
Blockly.Blocks["csharp_date_day"] = {
  init: function () {
    this.appendValueInput("compA").setCheck(null);
    this.appendDummyInput().appendField(".Day");

    this.setInputsInline(true);
    this.setPreviousStatement(false, null);
    this.setNextStatement(false, null);
    this.setOutput(true, "Day");
    this.setColour(cs_date_block_color);
    this.setTooltip("Day");
    this.setHelpUrl("");
  },
};

csharpGenerator.forBlock["csharp_date_day"] = function (block, generator) {
  // using
  generator.definitions_["import_system"] = "using System;";
  // Parameter
  var compA = generator.valueToCode(block, "compA", Order.NONE);
  // Code
  var codeText = `${compA}.Day`;

  return [codeText, Order.NONE];
};

///////////////////////////////////////////////////////////////////
// daynumber
Blockly.Blocks["csharp_date_daynumber"] = {
  init: function () {
    this.appendValueInput("compA").setCheck(null);
    this.appendDummyInput().appendField(".DayNumber");

    this.setInputsInline(true);
    this.setPreviousStatement(false, null);
    this.setNextStatement(false, null);
    this.setOutput(true, "DayNumber");
    this.setColour(cs_date_block_color);
    this.setTooltip("DayNumber");
    this.setHelpUrl("");
  },
};

csharpGenerator.forBlock["csharp_date_daynumber"] = function (
  block,
  generator
) {
  // using
  generator.definitions_["import_system"] = "using System;";
  // Parameter
  var compA = generator.valueToCode(block, "compA", Order.NONE);
  // Code
  var codeText = `${compA}.DayNumber`;

  return [codeText, Order.NONE];
};

///////////////////////////////////////////////////////////////////
// dayofweek
Blockly.Blocks["csharp_date_dayofweek"] = {
  init: function () {
    this.appendValueInput("compA").setCheck(null);
    this.appendDummyInput().appendField(".DayOfWeek");

    this.setInputsInline(true);
    this.setPreviousStatement(false, null);
    this.setNextStatement(false, null);
    this.setOutput(true, "DayOfWeek");
    this.setColour(cs_date_block_color);
    this.setTooltip("DayOfWeek");
    this.setHelpUrl("");
  },
};

csharpGenerator.forBlock["csharp_date_dayofweek"] = function (
  block,
  generator
) {
  // using
  generator.definitions_["import_system"] = "using System;";
  // Parameter
  var compA = generator.valueToCode(block, "compA", Order.NONE);
  // Code
  var codeText = `${compA}.DayOfWeek`;

  return [codeText, Order.NONE];
};

///////////////////////////////////////////////////////////////////
// dayofyear
Blockly.Blocks["csharp_date_dayofyear"] = {
  init: function () {
    this.appendValueInput("compA").setCheck(null);
    this.appendDummyInput().appendField(".DayOfYear");

    this.setInputsInline(true);
    this.setPreviousStatement(false, null);
    this.setNextStatement(false, null);
    this.setOutput(true, "DayOfYear");
    this.setColour(cs_date_block_color);
    this.setTooltip("DayOfYear");
    this.setHelpUrl("");
  },
};

csharpGenerator.forBlock["csharp_date_dayofyear"] = function (
  block,
  generator
) {
  // using
  generator.definitions_["import_system"] = "using System;";
  // Parameter
  var compA = generator.valueToCode(block, "compA", Order.NONE);
  // Code
  var codeText = `${compA}.DayOfYear`;

  return [codeText, Order.NONE];
};

///////////////////////////////////////////////////////////////////
// month
Blockly.Blocks["csharp_date_month"] = {
  init: function () {
    this.appendValueInput("compA").setCheck(null);
    this.appendDummyInput().appendField(".Month");

    this.setInputsInline(true);
    this.setPreviousStatement(false, null);
    this.setNextStatement(false, null);
    this.setOutput(true, "Month");
    this.setColour(cs_date_block_color);
    this.setTooltip("Month");
    this.setHelpUrl("");
  },
};

csharpGenerator.forBlock["csharp_date_month"] = function (block, generator) {
  // using
  generator.definitions_["import_system"] = "using System;";
  // Parameter
  var compA = generator.valueToCode(block, "compA", Order.NONE);
  // Code
  var codeText = `${compA}.Month`;

  return [codeText, Order.NONE];
};

///////////////////////////////////////////////////////////////////
// year
Blockly.Blocks["csharp_date_year"] = {
  init: function () {
    this.appendValueInput("compA").setCheck(null);
    this.appendDummyInput().appendField(".Year");

    this.setInputsInline(true);
    this.setPreviousStatement(false, null);
    this.setNextStatement(false, null);
    this.setOutput(true, "Year");
    this.setColour(cs_date_block_color);
    this.setTooltip("Year");
    this.setHelpUrl("");
  },
};

csharpGenerator.forBlock["csharp_date_year"] = function (block, generator) {
  // using
  generator.definitions_["import_system"] = "using System;";
  // Parameter
  var compA = generator.valueToCode(block, "compA", Order.NONE);
  // Code
  var codeText = `${compA}.Year`;

  return [codeText, Order.NONE];
};

///////////////////////////////////////////////////////////////////
// adddays
Blockly.Blocks["csharp_date_adddays"] = {
  init: function () {
    this.appendValueInput("compA").setCheck(null);
    this.appendDummyInput().appendField(".AddDays(");
    this.appendValueInput("day").setCheck("Number");
    this.appendDummyInput().appendField(")");

    this.setInputsInline(true);
    this.setPreviousStatement(false, null);
    this.setNextStatement(false, null);
    this.setOutput(true, "AddDays");
    this.setColour(cs_date_block_color);
    this.setTooltip("AddDays");
    this.setHelpUrl("");
  },
};

csharpGenerator.forBlock["csharp_date_adddays"] = function (block, generator) {
  // using
  generator.definitions_["import_system"] = "using System;";
  // Parameter
  var compA = generator.valueToCode(block, "compA", Order.NONE);
  var compB = generator.valueToCode(block, "day", Order.NONE);
  // Code
  var codeText = `${compA}.AddDays(${compB})`;

  return [codeText, Order.NONE];
};

///////////////////////////////////////////////////////////////////
// addmonths
Blockly.Blocks["csharp_date_addmonths"] = {
  init: function () {
    this.appendValueInput("compA").setCheck(null);
    this.appendDummyInput().appendField(".AddMonths(");
    this.appendValueInput("month").setCheck("Number");
    this.appendDummyInput().appendField(")");

    this.setInputsInline(true);
    this.setPreviousStatement(false, null);
    this.setNextStatement(false, null);
    this.setOutput(true, "AddMonths");
    this.setColour(cs_date_block_color);
    this.setTooltip("AddMonths");
    this.setHelpUrl("");
  },
};

csharpGenerator.forBlock["csharp_date_addmonths"] = function (
  block,
  generator
) {
  // using
  generator.definitions_["import_system"] = "using System;";
  // Parameter
  var compA = generator.valueToCode(block, "compA", Order.NONE);
  var compB = generator.valueToCode(block, "month", Order.NONE);
  // Code
  var codeText = `${compA}.AddMonths(${compB})`;

  return [codeText, Order.NONE];
};

///////////////////////////////////////////////////////////////////
// addyears
Blockly.Blocks["csharp_date_addyears"] = {
  init: function () {
    this.appendValueInput("compA").setCheck(null);
    this.appendDummyInput().appendField(".AddYears(");
    this.appendValueInput("year").setCheck("Number");
    this.appendDummyInput().appendField(")");

    this.setInputsInline(true);
    this.setPreviousStatement(false, null);
    this.setNextStatement(false, null);
    this.setOutput(true, "AddYears");
    this.setColour(cs_date_block_color);
    this.setTooltip("AddYears");
    this.setHelpUrl("");
  },
};

csharpGenerator.forBlock["csharp_date_addyears"] = function (block, generator) {
  // using
  generator.definitions_["import_system"] = "using System;";
  // Parameter
  var compA = generator.valueToCode(block, "compA", Order.NONE);
  var compB = generator.valueToCode(block, "year", Order.NONE);
  // Code
  var codeText = `${compA}.AddYears(${compB})`;

  return [codeText, Order.NONE];
};

///////////////////////////////////////////////////////////////////
// tolongdatestring
Blockly.Blocks["csharp_date_tolongdatestring"] = {
  init: function () {
    this.appendValueInput("compA").setCheck(null);
    this.appendDummyInput().appendField(".ToLongDateString()");

    this.setInputsInline(true);
    this.setPreviousStatement(false, null);
    this.setNextStatement(false, null);
    this.setOutput(true, "AddYears");
    this.setColour(cs_date_block_color);
    this.setTooltip("AddYears");
    this.setHelpUrl("");
  },
};

csharpGenerator.forBlock["csharp_date_tolongdatestring"] = function (
  block,
  generator
) {
  // using
  generator.definitions_["import_system"] = "using System;";
  // Parameter
  var compA = generator.valueToCode(block, "compA", Order.NONE);
  // Code
  var codeText = `${compA}.ToLongDateString()`;

  return [codeText, Order.NONE];
};

///////////////////////////////////////////////////////////////////
// toshortdatestring
Blockly.Blocks["csharp_date_toshortdatestring"] = {
  init: function () {
    this.appendValueInput("compA").setCheck(null);
    this.appendDummyInput().appendField(".ToLongDateString()");

    this.setInputsInline(true);
    this.setPreviousStatement(false, null);
    this.setNextStatement(false, null);
    this.setOutput(true, "ToShortDateString");
    this.setColour(cs_date_block_color);
    this.setTooltip("ToShortDateString");
    this.setHelpUrl("");
  },
};

csharpGenerator.forBlock["csharp_date_toshortdatestring"] = function (
  block,
  generator
) {
  // using
  generator.definitions_["import_system"] = "using System;";
  // Parameter
  var compA = generator.valueToCode(block, "compA", Order.NONE);
  // Code
  var codeText = `${compA}.ToShortDateString()`;

  return [codeText, Order.NONE];
};

///////////////////////////////////////////////////////////////////
// tostring
Blockly.Blocks["csharp_date_tostring"] = {
  init: function () {
    this.appendValueInput("compA").setCheck(null);
    this.appendDummyInput().appendField(".ToString()");

    this.setInputsInline(true);
    this.setPreviousStatement(false, null);
    this.setNextStatement(false, null);
    this.setOutput(true, "ToString");
    this.setColour(cs_date_block_color);
    this.setTooltip("ToString");
    this.setHelpUrl("");
  },
};

csharpGenerator.forBlock["csharp_date_tostring"] = function (block, generator) {
  // using
  generator.definitions_["import_system"] = "using System;";
  // Parameter
  var compA = generator.valueToCode(block, "compA", Order.NONE);
  // Code
  var codeText = `${compA}.ToString()`;

  return [codeText, Order.NONE];
};
