import * as Blockly from "blockly/core";
import { csharpGenerator, Order } from "blockly/csharp";
import * as BlockColor from "./const.js";

// ブロックの配色
var cs_random_block_color = BlockColor.cs_block_color;

// Timerブロック定義
const category_randomDef = `
    <category name="Random" colour="${cs_random_block_color}">
    <block type="csharp_random_new"></block>
    <block type="csharp_random_next"></block>
    <block type="csharp_random_next_area"></block>

    </category>`;

export { category_randomDef };

///////////////////////////////////////////////////////////////////
// new
Blockly.Blocks["csharp_random_new"] = {
  init: function () {
    this.appendDummyInput().appendField("new Random()");

    this.setInputsInline(true);
    this.setPreviousStatement(false, null);
    this.setNextStatement(false, null);
    this.setOutput(true, "Random");
    this.setColour(cs_random_block_color);
    this.setTooltip("Random");
    this.setHelpUrl("");
  },
};

csharpGenerator.forBlock["csharp_random_new"] = function (block, generator) {
  // using
  generator.definitions_["import_system"] = "using System;";
  // Parameter
  // Code
  var codeText = `new Random()`;

  return [codeText, Order.NONE];
};

///////////////////////////////////////////////////////////////////
// next
Blockly.Blocks["csharp_random_next"] = {
  init: function () {
    this.appendValueInput("compA").setCheck(null);
    this.appendDummyInput().appendField(".Next()");

    this.setInputsInline(true);
    this.setPreviousStatement(false, null);
    this.setNextStatement(false, null);
    this.setOutput(true, "Next");
    this.setColour(cs_random_block_color);
    this.setTooltip("Next");
    this.setHelpUrl("");
  },
};

csharpGenerator.forBlock["csharp_random_next"] = function (block, generator) {
  // using
  generator.definitions_["import_system"] = "using System;";
  // Parameter
  var compA = generator.valueToCode(block, "compA", Order.NONE);
  // Code
  var codeText = `${compA}.Next()`;

  return [codeText, Order.NONE];
};

///////////////////////////////////////////////////////////////////
// next
Blockly.Blocks["csharp_random_next_area"] = {
  init: function () {
    this.appendValueInput("compA").setCheck(null);
    this.appendDummyInput().appendField(".Next(");
    this.appendValueInput("compB").setCheck("Number");
    this.appendDummyInput().appendField(",");
    this.appendValueInput("compC").setCheck("Number");
    this.appendDummyInput().appendField(")");

    this.setInputsInline(true);
    this.setPreviousStatement(false, null);
    this.setNextStatement(false, null);
    this.setOutput(true, "Next");
    this.setColour(cs_random_block_color);
    this.setTooltip("Next");
    this.setHelpUrl("");
  },
};

csharpGenerator.forBlock["csharp_random_next_area"] = function (
  block,
  generator
) {
  // using
  generator.definitions_["import_system"] = "using System;";
  // Parameter
  var compA = generator.valueToCode(block, "compA", Order.NONE);
  var compB = generator.valueToCode(block, "compB", Order.NONE);
  var compC = generator.valueToCode(block, "compC", Order.NONE);
  // Code
  var codeText = `${compA}.Next(${compB},${compC})`;

  return [codeText, Order.NONE];
};
