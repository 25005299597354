import * as Blockly from "blockly/core";
import { csharpGenerator, Order } from "blockly/csharp";
import * as BlockColor from "./const.js";

// ブロックの配色
var cs_class_block_color = BlockColor.cs_block_color;

// Timerブロック定義
const category_classDef = `
    <category name="Class" colour="${cs_class_block_color}">
    <block type="cs_main"></block>
    <block type="cs_class"></block>
    <block type="cs_exclass"></block>
    <block type="cs_new"></block>
    <block type="cs_equal"></block>
    <block type="cs_dot"></block>
    <block type="cs_dot_method"></block>
    <block type="cs_this"></block>
    </category>`;

// <block type="cs_namespace"></block>

export { category_classDef };

///////////////////////////////////////////////////////////////////
// main
Blockly.Blocks["cs_main"] = {
  init: function () {
    this.appendDummyInput().appendField("Main");
    this.appendStatementInput("Codes").setCheck(null);
    this.setInputsInline(true);
    this.setPreviousStatement(true, null);
    this.setNextStatement(true, null);
    this.setColour(cs_class_block_color);
    this.setTooltip("main");
    this.setHelpUrl("");
  },
};

csharpGenerator.forBlock["cs_main"] = function (block, generator) {
  // using
  generator.definitions_["import_system"] = "using System;";
  // Parameter
  var Codes = generator.statementToCode(block, "Codes");

  // Code
  //var codeText = `public static void Main(string[] args){
  var codeText = `public void Main(){
    ${Codes}
}`;

  return codeText;
};

///////////////////////////////////////////////////////////////////
// namespace
Blockly.Blocks["cs_namespace"] = {
  init: function () {
    this.appendDummyInput().appendField("namespace");
    this.appendDummyInput().appendField(
      new Blockly.FieldTextInput("namespaceName"),
      "namespace"
    );
    this.appendStatementInput("Codes").setCheck(null);
    this.setInputsInline(true);
    this.setPreviousStatement(true, null);
    this.setNextStatement(true, null);
    this.setColour(cs_class_block_color);
    this.setTooltip("namespace");
    this.setHelpUrl("");
  },
};

csharpGenerator.forBlock["cs_namespace"] = function (block, generator) {
  // using
  generator.definitions_["import_system"] = "using System;";
  // Parameter
  var Codes = generator.statementToCode(block, "Codes");
  var ClassName = block.getFieldValue("namespace");

  // Code
  var codeText = `namespace ${ClassName}{
    ${Codes}
}`;

  return codeText;
};

///////////////////////////////////////////////////////////////////
// class
Blockly.Blocks["cs_class"] = {
  init: function () {
    this.appendDummyInput().appendField(
      new Blockly.FieldDropdown([
        ["public", "public"],
        ["private", "private"],
        ["protected", "protected"],
      ]),
      "AccessDescriptor"
    );
    this.appendDummyInput().appendField("class");
    this.appendDummyInput().appendField(
      new Blockly.FieldTextInput("ClassName"),
      "ClassName"
    );
    this.appendStatementInput("Codes").setCheck(null);
    this.setInputsInline(true);
    this.setPreviousStatement(true, null);
    this.setNextStatement(true, null);
    this.setColour(cs_class_block_color);
    this.setTooltip("class");
    this.setHelpUrl("");
  },
};

csharpGenerator.forBlock["cs_class"] = function (block, generator) {
  // using
  generator.definitions_["import_system"] = "using System;";
  // Parameter
  var AccessDescriptor = block.getFieldValue("AccessDescriptor");
  var Codes = generator.statementToCode(block, "Codes");
  var ClassName = block.getFieldValue("ClassName");

  // Code
  var codeText = `${AccessDescriptor} class ${ClassName}{
    ${Codes}
}`;

  return codeText;
};

///////////////////////////////////////////////////////////////////
// exclass
Blockly.Blocks["cs_exclass"] = {
  init: function () {
    this.appendDummyInput().appendField(
      new Blockly.FieldDropdown([
        ["public", "public"],
        ["private", "private"],
        ["protected", "protected"],
      ]),
      "AccessDescriptor"
    );
    this.appendDummyInput().appendField("class");
    this.appendDummyInput().appendField(
      new Blockly.FieldTextInput("ClassName"),
      "ClassName"
    );
    this.appendDummyInput().appendField(":");
    this.appendDummyInput().appendField(
      new Blockly.FieldTextInput("ParentClassName"),
      "ParentClassName"
    );
    this.appendStatementInput("Codes").setCheck(null);
    this.setInputsInline(true);
    this.setPreviousStatement(true, null);
    this.setNextStatement(true, null);
    this.setColour(cs_class_block_color);
    this.setTooltip("class");
    this.setHelpUrl("");
  },
};

csharpGenerator.forBlock["cs_exclass"] = function (block, generator) {
  // using
  generator.definitions_["import_system"] = "using System;";
  // Parameter
  var AccessDescriptor = block.getFieldValue("AccessDescriptor");
  var Codes = generator.statementToCode(block, "Codes");
  var ClassName = block.getFieldValue("ClassName");
  var ParentClassName = block.getFieldValue("ParentClassName");

  // Code
  var codeText = `${AccessDescriptor} class ${ClassName} : ${ParentClassName}{
    ${Codes}
}`;

  return codeText;
};

///////////////////////////////////////////////////////////////////
// new
Blockly.Blocks["cs_new"] = {
  init: function () {
    this.appendDummyInput()
      .appendField("new")
      .appendField(new Blockly.FieldTextInput("ClassName"), "ClassName");
    this.setInputsInline(true);
    this.setOutput(true, null);
    this.setColour(cs_class_block_color);
    this.setTooltip("new");
    this.setHelpUrl("");
  },
};

csharpGenerator.forBlock["cs_new"] = function (block, generator) {
  // using
  generator.definitions_["import_system"] = "using System;";
  // Parameter
  var ClassName = block.getFieldValue("ClassName");
  var code = `new ${ClassName}()`;
  // Code
  return [code, Order.NONE];
};

///////////////////////////////////////////////////////////////////
// equal
Blockly.Blocks["cs_equal"] = {
  init: function () {
    this.appendValueInput("classObj").setCheck(null);
    this.appendDummyInput().appendField("=");
    this.appendValueInput("member").setCheck(null);
    this.setInputsInline(true);
    this.setPreviousStatement(true, null);
    this.setNextStatement(true, null);
    this.setColour(cs_class_block_color);
    this.setTooltip("dot");
    this.setHelpUrl("");
  },
};

csharpGenerator.forBlock["cs_equal"] = function (block, generator) {
  // using
  generator.definitions_["import_system"] = "using System;";
  // Parameter
  var classObj = generator.valueToCode(block, "classObj", Order.NONE);
  var member = generator.valueToCode(block, "member", Order.NONE);
  var code = classObj + "=" + member + ";";
  // Code
  return code;
};

///////////////////////////////////////////////////////////////////
// dot
Blockly.Blocks["cs_dot"] = {
  init: function () {
    this.appendValueInput("classObj").setCheck(null);
    this.appendDummyInput().appendField(".");
    this.appendValueInput("member").setCheck(null);
    this.setInputsInline(true);
    this.setOutput(true, null);
    this.setColour(cs_class_block_color);
    this.setTooltip("dot");
    this.setHelpUrl("");
  },
};

csharpGenerator.forBlock["cs_dot"] = function (block, generator) {
  // using
  generator.definitions_["import_system"] = "using System;";
  // Parameter
  var classObj = generator.valueToCode(block, "classObj", Order.NONE);
  var member = generator.valueToCode(block, "member", Order.NONE);
  var code = classObj + "." + member;
  // Code
  return [code, Order.NONE];
};

///////////////////////////////////////////////////////////////////
// dot
Blockly.Blocks["cs_dot_method"] = {
  init: function () {
    this.appendValueInput("classObj").setCheck(null);
    this.appendDummyInput().appendField(".");
    this.appendValueInput("member").setCheck(null);
    this.setInputsInline(true);
    this.setPreviousStatement(true, null);
    this.setNextStatement(true, null);
    this.setColour(cs_class_block_color);
    this.setTooltip("dot");
    this.setHelpUrl("");
  },
};

csharpGenerator.forBlock["cs_dot_method"] = function (block, generator) {
  // using
  generator.definitions_["import_system"] = "using System;";
  // Parameter
  var classObj = generator.valueToCode(block, "classObj", Order.NONE);
  var member = generator.valueToCode(block, "member", Order.NONE);
  var code = classObj + "." + member + ";";
  // Code
  return code;
};

///////////////////////////////////////////////////////////////////
// this
Blockly.Blocks["cs_this"] = {
  init: function () {
    this.appendDummyInput().appendField("this");
    this.setInputsInline(true);
    this.setOutput(true, null);
    this.setColour(cs_class_block_color);
    this.setTooltip("this");
    this.setHelpUrl("");
  },
};

csharpGenerator.forBlock["cs_this"] = function (block, generator) {
  // using
  generator.definitions_["import_system"] = "using System;";
  // Parameter
  var code = `this`;
  // Code
  return [code, Order.NONE];
};
